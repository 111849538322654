import types from './types';

export const setMessage = (flag, code, uuidNumber = null) => ({
    type: types.SET_MESSAGE,
    flag,
    code,
    uuidNumber
});

export const hideMessage = (dispatch) => {
    dispatch(setMessage(false, '200'));
}

export default {
    setMessage,
    hideMessage
}