import React, { Component } from 'react';
import { map, reverse, isEmpty } from 'lodash';
import TableHeader from '../atoms/TableHeader';
import SortByMobile from '../atoms/SortByMobile';
import { maxWidthValue, minWidthValue } from '../../helpers/constant';
// reponsive
import MediaQuery from "react-responsive";

class OtherCommitteeListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            othercommittee: props.otherCommitteeList,
            lastSortedKey: 'committeedesignation',
            orderBy: 1,
            activeTabKeyMobile: ''
        };
    }

    handleTableHeaderClick = (itemName, order_By) => {
        const { othercommittee, lastSortedKey } = this.state;
        if (itemName === lastSortedKey) {
            this.setState({
                othercommittee: reverse(othercommittee),
                orderBy: order_By
            });
        }
    }

    handleClickHeader = (itemName, order_By) => {
        this.handleTableHeaderClick(itemName, order_By);
    }

    render() {
        const { othercommittee, orderBy } = this.state;
        let headerProps = [
            { 'title': 'Committee Designation', 'hasSorting': !this.props.isPDF, "sortKey": 'committeedesignation', 'orderBy': orderBy },
            { 'title': 'Classification', 'hasSorting': false },
            { 'title': 'Official Vote', 'hasSorting': false }
        ];

        if (isEmpty(othercommittee)) {
            return (
                <div className="no-notes mb20">No records to display.</div>
            );
        }

        return (
            <React.Fragment>
                <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
                    <div className="popup-subhead-content" data-testid="OtherCommitteeListCmp">
                        <table className="table astm-table roasterTable">
                            <thead>
                                <tr key="1">
                                    <TableHeader
                                        headerProps={[
                                            { 'title': 'Committee Designation', 'hasSorting': !this.props.isPDF, "sortKey": 'committeedesignation', 'orderBy': orderBy },
                                            { 'title': 'Classification', 'hasSorting': false },
                                            { 'title': 'Official Vote', 'hasSorting': false }
                                        ]}
                                        onClickHeader={this.handleTableHeaderClick}
                                    >
                                    </TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {map(othercommittee, (commiittee, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ 'padding-left': '5px' }}>{commiittee.committeedesignation}</td>
                                            <td>{commiittee.classification}</td>
                                            <td style={{ 'text-align': 'left' }}>{commiittee.officialvote}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </MediaQuery>

                <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
                    <div className="accordion-sort clearfix">
                        <SortByMobile
                            label={'Sort by'}
                            headerProps={headerProps}
                            onClickHeader={this.handleClickHeader}
                        />
                    </div>

                    <table className="table astm-table table-striped roasterTable">
                        <tbody>
                            {map(othercommittee, (commiittee, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="astm-table-td astm-table-td--mobile">
                                            <div className="astm-table-td--mobile-content">
                                                <div>
                                                    <span className="table-header astm-type-body bold">Committee Designation: </span>
                                                    <span className="table-data astm-type-body">{commiittee.committeedesignation}</span>
                                                </div>

                                                <div>
                                                    <span className="table-header astm-type-body-bold bold">Classification: </span>
                                                    <span className="table-data astm-type-body">{commiittee.classification}</span>
                                                </div>

                                                <div>
                                                    <span className="table-header astm-type-body-bold bold">Official Vote: </span>
                                                    <span className="table-data astm-type-body">{commiittee.officialvote}</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </MediaQuery>
            </React.Fragment>
        );
    }
}

export default OtherCommitteeListComponent;