import React from 'react'
import { Form } from 'react-bootstrap'

const RenderInputField = ({ testid, id, label, name, type, required, disabled, placeholder, className, value, onChange, onKeyUp }) => {

    const handleChange = (event) => {
        onChange && onChange(event);
    }

    const handleKeyUp = (event) => {
        onKeyUp && onKeyUp(event);
    }

    return (
        <Form.Group data-testid="RadioInputCmp">
            {(required && label) && (
                <Form.Label className="astm-type-body--lead" htmlFor={id}>
                    <i className="asterisk  icon"></i>
                    {label}
                </Form.Label>
            )}

            <Form.Control
                data-testid={testid}
                id={id ? id : ''}
                type={type ? type : ''}
                name={name ? name : ''}
                placeholder={placeholder ? placeholder : ''}
                className={className ? className : ''}
                value={value ? value : ''}
                disabled={disabled ? disabled : false}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
            />
        </Form.Group>
    )
}
export default RenderInputField
