import types from './types';

export const setAppLoader = (value) => ({
    type: types.SET_APP_LOADER,
    value
});


export default {
    setAppLoader
}