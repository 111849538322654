import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { assign } from 'lodash';
import { getUserInfo } from '../../helpers/utilCommon';

export const IdentityDelegationButton = ({ ...props }) => {
    
    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        setUserInfo(getUserInfo());
    }, []);

    const customProps = assign(props, {
        id: props.id ? props.id : props.name ? props.name : "identity-delegation-button",
        disabled: userInfo?.IdentityDelegation?.permission === 'view',
        className: userInfo?.IdentityDelegation?.permission === 'view'
        ?`${props.className} astm-identity-delegation-button`
        : props.className
    });
    
    return (
        <Button {...customProps}>
            {props.children}
        </Button>
    );
}