import { datadogLogs } from "@datadog/browser-logs";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'astm-ux-design-system/dist/bundle.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-widgets/dist/css/react-widgets.css';
import './src/assets/themes/main.css';
import './src/assets/css/app.css';
import 'polyfill-array-includes';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'webcrypto-shim';
import App from './src/components/pages/app';
import { BrowserRoutesProvider, OIDC, getAuthHeaderToken, verifyMECallForSession } from './src/helpers/OktaUtility';
import { generateUUID } from './src/helpers/utilCommon';
import { BannerProvider } from './src/providers/banner';
import * as serviceWorker from './src/serviceWorker';
import store from './src/store';
import ErrorBoundary from "./src/components/shared-components/ErrorBoundary";
import FlagFeaturesProvider, { FlagFeaturesContext as CtxFeatureFlag } from "./src/providers/flagFeature.js";

const uniqueId = generateUUID();

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
  site: process.env.REACT_APP_DATADOG_SITE,
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: process.env.REACT_APP_DATADOG_ENV,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['error'],
  storeContextsAcrossPages: true,
  allowUntrustedEvents: true,
  sendLogsAfterSessionExpiration: true,
  beforeSend: (event) => {
    if (event.message.indexOf('Warning:') === 0) {
        return false;
    } else {
        event.message = `${uniqueId}:: ${event.message}`
    }
  }
});

(() => {
  let isJiraEnabled = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_ENABLED;
  if (isJiraEnabled === 'true') {
    let src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL;
    // eslint-disable-next-line no-useless-escape
    document.write('<script src="' + src + '"><\/script>');
  }
})();

// TODO : set up required props.
const OKTA_PROPS = {
  OIDC,
  verifyMECallForSession,
  getAuthHeaderToken,
  isLoadOKTAConfigurationTest: true
}


createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router basename='/'>
        <BrowserRoutesProvider OIDC_CONFIG={OIDC}>
          <FlagFeaturesProvider>
            <BannerProvider>
              <App {...OKTA_PROPS} featureFlag={CtxFeatureFlag} />
            </BannerProvider>
          </FlagFeaturesProvider>
        </BrowserRoutesProvider>
      </Router>
    </Provider>
  </ErrorBoundary>
);
serviceWorker.unregister();