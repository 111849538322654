import types from './types';

export const updatePendingApplication = updatedApplication => ({
    type: types.UPDATE_PENDING_APPLICATION,
    payload: updatedApplication
});

export const setAllCommitteeList = committeeList => ({
    type: types.SET_ALL_COMMITTEE_LIST,
    payload: committeeList
});

export const setSelectedCommittee = committeeid => ({
    type: types.SET_SELECTED_COMMITTEE,
    payload: committeeid
});
  
export const showSummaryLoader = () => ({
    type: types.SHOW_SUMMARY_LOADER,
});
  
export const hideSummaryLoader = () => ({
    type: types.HIDE_SUMMARY_LOADER,
});
  
export const setCommitteeSummary = summaryObj => ({
    type: types.SET_COMMITTEE_SUMMARY,
    payload: summaryObj
});
  
export const showMemberLoader = () => ({
    type: types.SHOW_MEMBER_LOADER,
});
  
export const setMemberList = membersObj => ({
    type: types.SET_MEMBER_LIST,
    payload: membersObj
});

export const setClassListLoader = (status) => ({
    type: types.SET_CLASS_LIST_LOADER,
    status
});

export const setVoteListLoader = (status) => ({
    type: types.SET_VOTE_LIST_LOADER,
    status
});
  
export const setCommitteeClassificationsList = committeeClassificationObj => ({
    type: types.SET_COMMITTEE_CLASSIFICATION_LIST,
    payload: committeeClassificationObj
});
  
export const setVoteList = voteObj => ({
    type: types.SET_VOTE_LIST,
    payload: voteObj
});
  
export const setRosterReport = value => ({
    type: types.SET_ROSTER_REPORT,
    payload: value
});

export const hideMemberLoader = () => ({
    type: types.HIDE_MEMBER_LOADER,
});

export const setMemberId = memberid => ({
    type: types.SET_MEMBER_ID,
    payload: memberid
});

export const setCurrentPopupOrigin = popupOrigin => ({
    type: types.SET_CURRENT_POPUP_ORIGIN,
    payload: popupOrigin
});

export default {
    setSelectedCommittee,
    showSummaryLoader,
    hideSummaryLoader,
    setCommitteeSummary,
    showMemberLoader,
    hideMemberLoader,
    setMemberList,
    setCommitteeClassificationsList,
    setVoteList,
    setMemberId,
    setCurrentPopupOrigin
}