import React from 'react'

const StylesheetGenerator = props => {
    let { stylesheet } = props;
    let sheet = "<style>" + stylesheet + "<style/>";    
    return (
        <div dangerouslySetInnerHTML={{__html: sheet }} />
    );
}

export default StylesheetGenerator