import React from 'react';
import { useDispatch } from "react-redux";
import { setAppLoader } from '../redux/app/actions';
import { flagFeature } from '../services/appServices';

export const FlagFeaturesContext = React.createContext(null);

function FlagFeaturesProvider({ children }) {
  const [flagFeatures, setFlagFeatures] = React.useState(null);
  const dispatch = useDispatch();
  const [loadChild, setLoadChild] = React.useState(false);

  React.useEffect(() => {
    dispatch(setAppLoader(true));
      dispatch(flagFeature((featureResponse) => {
      setLoadChild(true);
      dispatch(setAppLoader(false))
        setFlagFeatures(featureResponse)
    }));
}, []);
    const value = React.useMemo(
      () => ({
        flagFeatures
      }),
      [flagFeatures]
    );
  
    return <FlagFeaturesContext.Provider value={value}>{loadChild && children}</FlagFeaturesContext.Provider>;
  }
  export default FlagFeaturesProvider;

