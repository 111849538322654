import React from 'react'
import { Pagination } from 'react-bootstrap';

const Pager = (props) => {
  let active = props.activePage;
  let items = [];
  let ellipsisCount = props.ellipsisCount + 1;

  for (let number = 1; number <= props.totalPages; number++) {
    const paginationItem = <Pagination.Item key={number} data-page={number} active={number === active}>{number}</Pagination.Item>
    const paginationEllipsis = <Pagination.Ellipsis key={number} />

    if (number === active + ellipsisCount) {
      items.push(paginationEllipsis);
    }

    if (number === active - (ellipsisCount - 1) && active > ellipsisCount) {
      items.push(paginationEllipsis);
    }

    (number < active + ellipsisCount) && items.push(paginationItem);
    (number < active - (ellipsisCount - 1)) && items.pop(paginationItem);
  }

  const handleClick = (e) => {
    const clickValue = e.target.offsetParent.getAttribute('data-page') ? e.target.offsetParent.getAttribute('data-page') : e.target.getAttribute('data-page') ? e.target.getAttribute('data-page') : null;

    if (clickValue && props.activePage !== JSON.parse(clickValue)) {
      window.scrollTo({ top: 90, behavior: 'smooth' });
      return props.onPageChange(e, JSON.parse(clickValue));
    }
  }

  return (
    <nav data-testid="PagerCmp" aria-label="Page Navigation" role="navigation">
      <Pagination data-testid="PaginationCmp" onClick={handleClick} className="astm-pagination">
        {/*  <Pagination.First data-page={`1`} disabled={props.activePage === 1}><i name='step backward'>first</i></Pagination.First> */}
        <Pagination.Prev aria-label="Previous" data-page={`${(props.activePage === 1) ? 1 : (props.activePage - 1)}`} disabled={props.activePage === 1}>Previous</Pagination.Prev>
        {items}
        <Pagination.Next aria-label="Next" data-page={`${(props.activePage === props.totalPages) ? props.totalPages : props.activePage + 1}`} disabled={props.activePage === props.totalPages}>Next</Pagination.Next>
        {/* <Pagination.Last data-page={`${props.totalPages}`} disabled={props.activePage === props.totalPages}><i name='step forward'>last</i></Pagination.Last> */}
      </Pagination>
    </nav>
  )
}
export default Pager
