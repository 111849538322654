import types from './types';

const INITIAL_STATE = {
  isAppLoading: false,
  isMiniAppLoading: false,
}

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_APP_LOADER: {
      return {
        ...state,
        isAppLoading: action.value
      }
    }
    default: return state;
  }
}

export default appReducer;