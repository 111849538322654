import { logger, LOG_TYPE } from "../../../helpers/dataDog.logger";
import React from "react";

class ErrorBoundary extends React.Component {
    componentDidCatch = (error) => {
        logger(
            `Error Boundary: ${error}`,
            {},
            LOG_TYPE.error,
            error
        );
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;