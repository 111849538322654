import React from "react";

const SummaryStats = props => {
    const { committeeclassification } = props;
    return (
        <div data-testid="summaryStatsCmp">
            <ul>
                <li data-testid="totalOfficialVoters">
                    <span className="totalCount">{ committeeclassification.totalofficialvoters }</span>
                    <span className="totalTitle">Total Official Voters</span>
                </li>
                <li className="active-total-count" data-testid="totalMember">
                    <span className="totalCount">{ committeeclassification.totalmembers }</span>
                    <span className="totalTitle">Total Members</span>
                </li>
                <li data-testid="totalNonOfficialVoters">
                    <span className="totalCount">{ committeeclassification.totalnonofficialvoters }</span>
                    <span className="totalTitle">Total Non Official Voters</span>
                </li>
            </ul>
        </div>
    );
}
export default SummaryStats;
