import React from "react";

const PieChartTooltip = props => {
  if (props.active) {
    if(props.payload[0].name === "#hidden#") {
      return null;
    }
    
    let color = props.payload[0].payload.color; 
    color = color.substr(1);

    let class_name = `recharts-tooltip-item custom-chart-tooltip-${color}`;
    if(props.rightPointer) {
      class_name += " chart-right-arrow";
    }

    return (
      <div data-testid="PieChartCmp" className="recharts-default-tooltip">
        <ul className="recharts-tooltip-item-list">
          <li id="chart-tooltip" className={class_name}>
            <div className="recharts-tooltip-item-value">
              { props.payload[0].value }
            </div>
            <div className="recharts-tooltip-item-name">
              { props.payload[0].name }
            </div>
          </li>
        </ul>
      </div>
    );
  }
  return null;
}
export default PieChartTooltip;