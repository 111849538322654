import types from './types';

const INITIAL_STATE = {
  messageObj: {},
  userInfo: null,
  memberData: null,
  siteAddress: {},
  userPermission: null,
  isPermissionLoader: false
};

const loginReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.SET_USER_INFO: {
      const { payload } = action;
      return {
        ...state,
        userInfo: payload
      }
    }
    case types.SET_USER_PERMISSION: {
      const { payload } = action;
      return {
        ...state,
        userPermission: payload,
        isPermissionLoader: false
      }
    }
    case types.SIGN_OUT: {
      return {
        messageObj: {},
        userInfo: null,
        memberData: null,
        siteAddress: {},
        userPermission: null
      };
    }
    default: return state;
  }
}

export default loginReducer;