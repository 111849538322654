import React from 'react'
import { Form, Spinner } from 'react-bootstrap';


const RenderSelect = ({ onChange, options, loading, size, className, value, disabled, ...restProps }) => {
  return (
    <div data-testid="RadioSelectCmp" className={className}>
      <Form.Control as="select" size={size} {...restProps}
        disabled={disabled ? disabled : loading && loading ? loading : false}
        value={value || ''}
        className={'custom-select'}
        onChange={(event) => onChange(JSON.parse(event.target.value))}>
        {options && options.map(item => {
          return (<option key={item.key} value={item.value} role="option" aria-describedby={item.text}>{item.text}</option>)
        })}
      </Form.Control>
      {loading && <Spinner animation="border" size="sm" />}
    </div>
  )
};
export default RenderSelect