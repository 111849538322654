import types from './types';

const INITIAL_STATE = {
  rosterOrderObj: null,
  pendingOrderObj: null,
  waitingOrderObj: null,
  classFilter: [],
  voteFilter: [],
  basicSearchText: '',
  firstName: {
    search: '',
    results: [],
    isLoading: false
  },
  lastName: {
    search: '',
    results: [],
    isLoading: false
  },
  organizationName: {
    search: '',
    results: [],
    isLoading: false
  },
  advanceSearch: {
    firstNameSearchValue: '',
    lastNameSearchValue: '',
    fromDateSearchValue: '',
    toDateSearchValue: '',
    classSearchValue: [],
    voteSearchValue: [],
    organisationSearchValue: [],
    emailSearchValue: '',
    typeSearchValue: 'any',
    rangeFromDate: null,
    rangeToDate: null
  }
};

const filterReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.SET_ROSTER_SORT_ORDER: {
      return {
        ...state,
        rosterOrderObj: action.payload
      }
    }
    case types.CLEAR_ROSTER_SORT_ORDER: {
      return {
        ...state,
        rosterOrderObj: null
      }
    }

    case types.SET_PENDING_SORT_ORDER: {
      return {
        ...state,
        pendingOrderObj: action.payload
      }
    }
    case types.CLEAR_PENDING_SORT_ORDER: {
      return {
        ...state,
        pendingOrderObj: null
      }
    }

    case types.SET_WAITING_SORT_ORDER: {
      return {
        ...state,
        waitingOrderObj: action.payload
      }
    }
    case types.CLEAR_WAITING_SORT_ORDER: {
      return {
        ...state,
        waitingOrderObj: null
      }
    }

    case types.SET_CLASS_FILTER: {
      return {
        ...state,
        classFilter: action.payload
      }
    }
    case types.SET_VOTE_FILTER: {
      return {
        ...state,
        voteFilter: action.payload
      }
    }
    case types.SET_BASIC_SEARCH: {
      return {
        ...state,
        basicSearchText: action.payload
      }
    }
    case types.CLEAR_ALL_FILTER: {
      return {
        ...state,
        classFilter: [],
        voteFilter: [],
        basicSearchText: ''
      }
    }
    case types.SEARCH_BY_FIRST_NAME: {
      return {
        ...state,
        firstName: {
          search: action.payload.search,
          results: action.payload.results,
          isLoading: action.payload.isLoading
        } 
      }
    }
    case types.SEARCH_BY_LAST_NAME: {
      return {
        ...state,
        lastName: {
          search: action.payload.search,
          results: action.payload.results,
          isLoading: action.payload.isLoading
        }
      }
    }
    case types.SEARCH_BY_ORGANIZATION: {
      return {
        ...state,
        organizationName: {
          search: action.payload.search,
          results: action.payload.results,
          isLoading: action.payload.isLoading
        }
      }
    }
    case types.SET_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearch: action.payload
      }
    }
    case types.RESET_ADVANCE_SEARCH: {
      return {
        ...state,
        advanceSearch: {
          firstNameSearchValue: '',
          lastNameSearchValue: '',
          fromDateSearchValue: '',
          toDateSearchValue: '',
          classSearchValue: [],
          voteSearchValue: [],
          organisationSearchValue: [],
          emailSearchValue: '',
          typeSearchValue: 'any',
          rangeFromDate: null,
          rangeToDate: null
        }
      }
    }
    default:
      return state;
  }
}

export default filterReducer;