import React, { Component } from 'react';
import { connect } from "react-redux";
import * as types from '../../redux/roster/types'
import utilCommon from '../../helpers/utilCommon';

const ReactExport = () => {
  return import('react-data-export')
}

export class ExportExcel extends Component {

  constructor(props) {
    super(props);
    setTimeout(() => {
      props.downloadExcelFlag();
    }, 10000)
  }

  state = {
    ExcelFile: null
  }

  componentDidMount() {
    ReactExport().then(cmp => { this.setState({ ExcelFile: cmp.default.ExcelFile }); });
  }

  AddGoogleLog = (fileName) => {
    // Google Analytics code
    let dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'documentClick', 'detail': {
        'documentName': fileName + '.xlsx',
        'documentId': '',
        'version': '',
        'status': 'most recent',
        'industry': '',
        'documentPages': '',
        'publishDate': utilCommon.getcurday('/'),
        'custom': ''
      }
    });
  }

  render() {
    const { fileName, tabName, multiDataSet } = this.props;
    const { ExcelFile } = this.state;
    ExcelFile && fileName && this.AddGoogleLog(fileName);
    return ExcelFile && fileName ? (
      <ExcelFile hideElement={true} filename={fileName}>
        <ExcelFile.ExcelSheet dataSet={multiDataSet} name={tabName} />
      </ExcelFile>
    ) : null;
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  downloadExcelFlag: () => dispatch({ type: types.SET_ROSTER_EXCEL_DATA, downloadExcelFlag: false })
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportExcel)