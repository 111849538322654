import React, { useState } from 'react';
import { map } from "lodash";
import { Accordion, Card } from 'react-bootstrap';
import CustomToggle from '../atoms/CustomToggle';

const SummaryStatsTable = props => {

    const [selectedAccordianKey, setSummaryAccordianKey] = useState(false);
    const { classifications } = props;

    const handleAccordianKey = (key) => {
        setSummaryAccordianKey(key);
    }

    return (
        <Accordion className="astm-accordion astm-accordion--full-width" data-testid="summaryStatsTableCmp">
            <Card className="astm-card">
                <CustomToggle
                    selectedAccordianKey={selectedAccordianKey}
                    setAccordianKey={handleAccordianKey}
                    eventKey='StatsTable'
                    as={Card.Header}
                    collapseChild={(
                        <h2 className="mb-0" data-testid="moreBtn">
                            <button className="btn astm-btn btn-link txtCenter capitalize" type="button">More</button>
                        </h2>
                    )}
                    expendChild={(
                        <h2 className="mb-0" data-testid="lessBtn">
                            <button className="btn astm-btn btn-link txtCenter capitalize" type="button">Less</button>
                        </h2>
                    )}
                />
                <Accordion.Collapse eventKey="StatsTable">
                    <Card.Body>
                        <div className="SummaryTable">
                            <table>
                                <thead>
                                    <tr key="summaryTableHeader">
                                        <th>Classifications</th>
                                        <th>Official</th>
                                        <th>Non Official</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(classifications, (classObj) => (
                                        <tr key={classObj.classificationname + classObj.committeeclassificationid}>
                                            <td>
                                                <span style={{ 'backgroundColor': classObj.colorcode }}></span>
                                            &nbsp;{classObj.classificationname}
                                            </td>
                                            <td>{classObj.official}</td>
                                            <td>{classObj.nonofficial}</td>
                                            <td>{classObj.total}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="clearfix"></div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
export default SummaryStatsTable;
