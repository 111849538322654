import { datadogLogs, StatusType } from "@datadog/browser-logs";
export const LOG_TYPE = {
  debug: "debug",
  info: "info",
  warn: "warn",
  error: "error",
};
export const logger = (message, obj = {}, status = LOG_TYPE.debug, error) => {
  const genricObj = {
    ...obj,
    date: new Date().toDateString(),
  };
  datadogLogs.logger.log(message, genricObj, status, error);
};
