import React, { Component } from 'react';
import { map, isEqual } from 'lodash';

class TableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headerList: props.headerProps
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { headerProps } = nextProps;
        if (!isEqual(headerProps, prevState.headerList)) {
            return {
                headerList: headerProps
            };
        }
        return null;
    }

    // 0 - no sort
    // 1 - asc
    // 2 - desc
    onHeaderClick = (item, index) => {
        if (!item.hasSorting) return;

        let { headerList } = this.state;
        map(headerList, (_item, _index) => {
            if (_index !== index) {
                _item.orderBy = 0;
            }
        });

        let isThirdClick = false;
        const order = headerList[index].orderBy;
        if (item.thirdClickOff) {
            if (order === 1) {
                headerList[index].orderBy = 2;
            } else if (order === 2) {
                headerList[index].orderBy = 0;
                isThirdClick = true;
            } else {
                headerList[index].orderBy = 1;
            }
        } else {
            if (order === 1) {
                headerList[index].orderBy = 2;
            } else {
                headerList[index].orderBy = 1;
            }
        }
        this.setState({ headerList }, () => {
            this.props.onClickHeader(item.sortKey, headerList[index].orderBy, isThirdClick);
        });
    }

    render() {
        const { headerList } = this.state;

        return (
            map(headerList, (item, index) => {
                const { orderBy } = item;
                let iconClass = '';
                if (orderBy === 1) {
                    iconClass = 'fas fa-angle-up activeSort';
                } else if (orderBy === 2) {
                    iconClass = 'fas fa-angle-down activeSort';
                }

                return (
                    <th
                        data-testid="tableHeaderCmp"
                        scope='col'
                        key={item.sortKey ? item.sortKey : index}
                        className={item.hasSorting ? item.className : "noPointer " + item.className}
                        onClick={() => { this.onHeaderClick(item, index); }}
                        data-order={item.orderBy}
                    >
                        {item.title}
                        {item.hasSorting && (
                            <i aria-hidden="true" className={iconClass}></i>
                        )}
                    </th>
                );
            })
        );
    }
}
export default TableHeader