import React from 'react';
import { Multiselect as MultiselectComponent } from 'react-widgets' // Docs https://jquense.github.io/react-widgets/api/Multiselect/
import { assign } from 'lodash';

const MultiSelect = ({ changeHandler, className, label, placeholder, options, name, ...otherProps }) => {
    let optionsList = options.map(item => item['text']);
    const handlechangeHandler = (value, actionEvent, options) => {
        let val = options.filter(optionsItem => (value.indexOf(optionsItem.text) > -1)).map(filteredItem => filteredItem['value']);
        return changeHandler(val);
    };

    const handleValue = value => value && options.filter(item => (value.indexOf(item.value) > -1)).map(item => item.text);

    // TODO : Toggle handler for accessibility issue.
    const toggleHandler = (isOpen) => {
        let multiSelectRef = document.querySelector('[name=' + `${name}` + '] ul');
        if (multiSelectRef) {
            if (!isOpen) {
                multiSelectRef.setAttribute('aria-hidden', true);
            } else {
                multiSelectRef.setAttribute('aria-hidden', false);
            }
        }
    }

    const onBlurHandler = () => {
        let multiSelectRef = document.querySelector('[name=' + `${name}` + '] ul');
        if (multiSelectRef) {
            multiSelectRef.setAttribute('aria-hidden', true);
        }
    }

    const customInputs = assign(otherProps, {
        filter: 'contains',
        name: name ? name : '',
        className: className ? className : '',
        placeholder: placeholder ? placeholder : '',
        data: optionsList,
        messages: { emptyList: '', emptyFilter: 'No results found' },
        value: handleValue(otherProps.value),
        onChange: (value, actionEvent) => handlechangeHandler(value, actionEvent, options),
        onToggle: toggleHandler,
        onBlur: onBlurHandler
    });


    return (
        <div data-testid="MultiSelect">
            {label && <label>{label}</label>}
            <MultiselectComponent data-testid="MultiSelectCmp" {...customInputs} />
        </div>
    );
}
export default MultiSelect;