import React from 'react';
import { Form } from 'react-bootstrap';
import { assign } from 'lodash';

const RenderCheckbox = ({ ...otherProps }) => {

    let customProps = assign(otherProps, {
        id: otherProps.id ? otherProps.id : otherProps.name ? otherProps.name : "custom-checkbox",
        custom: otherProps.custom ? otherProps.custom : true,
        label: otherProps.label ? otherProps.label : '',
        type: 'checkbox',
        className: otherProps.className ? "astm-custom-radio " + otherProps.className : "astm-custom-radio"
    });

    return (
        <Form.Check data-testid="checkBoxCmp" {...customProps} />
    );
}

export default RenderCheckbox;