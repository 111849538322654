import React, { useState } from 'react';
import { Button, Form, FormControl, InputGroup, Dropdown } from 'react-bootstrap';

const pageLinkEnum = [
    {
        label: "All",
        value: 0,
        link: process.env.REACT_APP_CATALOG_SEARCH
    },
    {
        label: "Meetings & Symposia",
        value: 1,
        link: process.env.REACT_APP_MEETING_SYMPOSIA_SEARCH
    },
    {
        label: "Standardization News",
        value: 2,
        link: process.env.REACT_APP_STANDARDIZATION_NEWS_SEARCH
    }, {
        label: "News Releases",
        value: 3,
        link: process.env.REACT_APP_NEWS_RELEASES_SEARCH
    }
]

const SearchBox = () => {
    const [pageLink, setPageLink] = useState(0);
    const [searchText, setSearchText] = useState('');

    const handleSearch = (e) => {
        e.preventDefault();
        window.location.href = `${pageLinkEnum[pageLink].link}${searchText}`;
    }

    return (
        <div data-testid="SearchBoxCmp">
            <Form className="astm-form" onSubmit={handleSearch}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <Form.Group>
                            <Dropdown aria-label="basic search" value={pageLink} name='pageLink'
                                onSelect={(eventKey) => setPageLink(eventKey)}>
                                <Dropdown.Toggle variant="Primary" id="dropdown-basic">
                                    {pageLinkEnum[pageLink].label}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey={pageLinkEnum[0].value}>{pageLinkEnum[0].label}</Dropdown.Item>
                                    <Dropdown.Item eventKey={pageLinkEnum[1].value}>{pageLinkEnum[1].label}</Dropdown.Item>
                                    <Dropdown.Item eventKey={pageLinkEnum[2].value}>{pageLinkEnum[2].label}</Dropdown.Item>
                                    <Dropdown.Item eventKey={pageLinkEnum[3].value}>{pageLinkEnum[3].label}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Search topic, title, author, A53 ..."
                        aria-label="basic search text box"
                        aria-describedby="basic-search"
                        name='searchText'
                        defaultValue=""
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <InputGroup.Append>
                        <Button type="submit" variant="primary" aria-label="Search">
                            <i className="fas fa-search"></i>
                            <span className="goMobile">Go</span>
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </Form>
        </div>
    )
}

export default SearchBox;