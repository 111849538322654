import { clone, filter, find, isEmpty, map, min } from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Col, Row } from 'react-bootstrap';
import { connect } from "react-redux";
// reponsive
import MediaQuery from "react-responsive";
import { maxWidthValue, minWidthValue } from "../../helpers/constant.js";
import { setAdvanceSearch } from "../../redux/filterValues/actions";
import MemberListFilter from "../molecules/MemberListFilter";
import MemberListTable from "../molecules/MemberListTable";


class MemberListComponent extends Component {

  removeFilter(value) {
    const { advanceSearch } = this.props.filters;
    let updatedAdvanceSearch = clone(advanceSearch);
    let pos = advanceSearch.organisationSearchValue.indexOf(value.value);
    let classpos = advanceSearch.classSearchValue.indexOf(value.id);
    let votepos = advanceSearch.voteSearchValue.indexOf(value.id);
    switch (value.type) {
      case "f_name":
        updatedAdvanceSearch.firstNameSearchValue = "";
        break;
      case "l_name":
        updatedAdvanceSearch.lastNameSearchValue = "";
        break;
      case "j_date":
        updatedAdvanceSearch.fromDateSearchValue = "";
        updatedAdvanceSearch.toDateSearchValue = "";
        break;
      case "organization":
        updatedAdvanceSearch.organisationSearchValue.splice(pos, 1);
        break;
      case "email":
        updatedAdvanceSearch.emailSearchValue = "";
        break;
      case "class_filter":
        updatedAdvanceSearch.classSearchValue.splice(classpos, 1);
        break;
      case "vote_filter":
        updatedAdvanceSearch.voteSearchValue.splice(votepos, 1);
        break;
      default:
        break;
    }
    this.props.setAdvanceSearch(updatedAdvanceSearch);
  }

  modifyDateLength(date) {
    if (date < 10) {
      date = "0" + date;
    }
    return date;
  }

  render() {
    const {
      filters,
      committeeMemberList,
      committeeClassificationList,
      voteList
    } = this.props;
    const { classFilter, voteFilter, basicSearchText, advanceSearch } = filters;
    const {
      firstNameSearchValue,
      lastNameSearchValue,
      fromDateSearchValue,
      toDateSearchValue,
      classSearchValue,
      voteSearchValue,
      organisationSearchValue,
      emailSearchValue,
      typeSearchValue
    } = advanceSearch;

    let isFilterApplied = false;
    let appliedFilter = [];

    if (!isEmpty(firstNameSearchValue)) {
      appliedFilter.push({ value: firstNameSearchValue, type: "f_name" });
      isFilterApplied = true;
    }
    if (!isEmpty(lastNameSearchValue)) {
      appliedFilter.push({ value: lastNameSearchValue, type: "l_name" });
      isFilterApplied = true;
    }
    if (!isEmpty(fromDateSearchValue) || !isEmpty(toDateSearchValue)) {
      let startDate = !isEmpty(fromDateSearchValue) ? fromDateSearchValue : "";
      let endDate = !isEmpty(toDateSearchValue)
        ? toDateSearchValue
        : moment().format("MM/DD/YYYY");
      if (!isEmpty(startDate) && !isEmpty(endDate)) {
        const date = startDate + " - " + endDate;
        appliedFilter.push({ value: date, type: "j_date" });
        isFilterApplied = true;
      } else if (isEmpty(startDate)) {
        const datesArray = [];
        for (let i = 0; i < committeeMemberList.committeemembers.length; i++) {
          datesArray.push(
            new Date(committeeMemberList.committeemembers[i].joindate)
          );
        }
        let minDate = min(datesArray);
        minDate =
          this.modifyDateLength(minDate.getMonth() + 1) +
          "/" +
          this.modifyDateLength(minDate.getDate()) +
          "/" +
          minDate.getFullYear();
        const date = " " + minDate + " - " + endDate;
        appliedFilter.push({ value: date, type: "j_date" });
        isFilterApplied = true;
      }
    }

    if (!isEmpty(organisationSearchValue)) {
      map(organisationSearchValue, value => {
        appliedFilter.push({ value, type: "organization" });
        isFilterApplied = true;
      });
    }
    if (!isEmpty(emailSearchValue)) {
      appliedFilter.push({ value: emailSearchValue, type: "email" });
      isFilterApplied = true;
    }

    if (!isEmpty(classSearchValue)) {
      map(classSearchValue, classValue => {
        let classObj = {};
        if (classValue === -1) {
          classObj = {
            value: "All Classifications",
            id: classValue,
            type: "class_filter"
          };
        } else {
          let classDescription = find(committeeClassificationList, {
            committeeclassificationid: classValue
          });
          classObj = {
            value: classDescription.name,
            id: classValue,
            type: "class_filter"
          };
        }
        appliedFilter.push(classObj);
      });
      isFilterApplied = true;
    }
    if (voteSearchValue && voteSearchValue.length > 0 && !isEmpty(voteSearchValue)) {
      map(voteSearchValue, voteValue => {
        let voteObj = {};
        if (voteValue === -1) {
          voteObj = { value: "All Votes", id: voteValue, type: "vote_filter" };
        } else {
          let voteDescription = find(voteList, { voteid: voteValue });
          voteObj = {
            value: voteDescription.description,
            id: voteValue,
            type: "vote_filter"
          };
        }
        appliedFilter.push(voteObj);
      });
      isFilterApplied = true;
    }
    let filteredCommitteemembers = [];

    if (
      !isEmpty(committeeMemberList) &&
      !(isEmpty(classFilter) && isEmpty(voteFilter))
    ) {
      filteredCommitteemembers = filter(
        committeeMemberList.committeemembers,
        obj => {
          if (isFilterApplied) {
            // advance search
            let firstName = !isEmpty(firstNameSearchValue)
              ? obj.firstname === firstNameSearchValue
                ? true
                : false
              : true;
            let lastName = !isEmpty(lastNameSearchValue)
              ? obj.lastname === lastNameSearchValue
                ? true
                : false
              : true;
            let organization = !isEmpty(organisationSearchValue)
              ? organisationSearchValue.join("_").indexOf(obj.organization) > -1
                ? true
                : false
              : true;
            let email = !isEmpty(emailSearchValue)
              ? obj.email === emailSearchValue
                ? true
                : false
              : true;
            let joiDate = true;

            if (!isEmpty(fromDateSearchValue) || !isEmpty(toDateSearchValue)) {
              let startDate = !isEmpty(fromDateSearchValue)
                ? moment(fromDateSearchValue)
                : "";
              let endDate = !isEmpty(toDateSearchValue)
                ? moment(toDateSearchValue)
                : moment();
              let memberJoinDate = moment(obj.joindate.substr(0, 10));
              if (!isEmpty(startDate) && !isEmpty(endDate)) {
                joiDate = memberJoinDate.isBetween(
                  startDate,
                  endDate,
                  null,
                  "[]"
                );
              } else if (isEmpty(startDate)) {
                joiDate = memberJoinDate.isSameOrBefore(endDate);
              }
            }

            let isAdvClassificaion = false;
            if (
              classSearchValue.indexOf(-1) !== -1 ||
              classSearchValue.indexOf(obj.committeeclassificationid) !== -1
            ) {
              isAdvClassificaion = true;
            }

            let isAdvVote = false;
            if (voteSearchValue &&
              voteSearchValue.indexOf(-1) !== -1 ||
              voteSearchValue.indexOf(obj.voteid) !== -1
            ) {
              isAdvVote = true;
            }

            if (typeSearchValue === "all" && isEmpty(classSearchValue)) {
              isAdvClassificaion = true;
            }

            if (typeSearchValue === "all" && isEmpty(voteSearchValue)) {
              isAdvVote = true;
            }

            if (typeSearchValue === "any") {
              return (
                (!isEmpty(firstNameSearchValue) && firstName) ||
                (!isEmpty(lastNameSearchValue) && lastName) ||
                ((!isEmpty(fromDateSearchValue) ||
                  !isEmpty(toDateSearchValue)) &&
                  joiDate) ||
                (!isEmpty(organisationSearchValue) && organization) ||
                (!isEmpty(emailSearchValue) && email) ||
                isAdvClassificaion ||
                isAdvVote
              );
            } 
              return (
                firstName &&
                lastName &&
                joiDate &&
                organization &&
                email &&
                isAdvClassificaion &&
                isAdvVote
              );
            
          } 
            
            // basic search
            let isClass = find(classFilter, {
              key: obj.committeeclassificationid,
              checked: true
            });
            let isVote = find(voteFilter, { key: obj.voteCode, checked: true });
            let searchFlag = true;
            if (basicSearchText && basicSearchText.length > 1) {
              let searchText = basicSearchText.toLowerCase();
              let firstname = obj.firstname ? obj.firstname.toLowerCase() : "";
              let middlename = obj.middlename ? obj.middlename.toLowerCase() : "";
              let lastname = obj.lastname ? obj.lastname.toLowerCase() : "";
              let email = obj.email ? obj.email.toLowerCase() : "";
              let organization = obj.organization ? obj.organization.toLowerCase() : "";
              let inFirstName = firstname.indexOf(searchText) !== -1;
              let inMiddleName = middlename.indexOf(searchText) !== -1;
              let inLastName = lastname.indexOf(searchText) !== -1;
              let inEmail = email.indexOf(searchText) !== -1;
              let inOrganization = organization.indexOf(searchText) !== -1;
              if (inFirstName || inMiddleName || inLastName || inEmail || inOrganization) {
                searchFlag = true;
              } else {
                searchFlag = false;
              }
            }
            if (isClass && isVote && searchFlag) {
              return true;
            }
          
        }
      );
    }

    let updatedProps = clone(this.props);
    updatedProps.committeeMemberList = {
      committeemembers: filteredCommitteemembers,
      totalrecords: filteredCommitteemembers.length
    };

    return <>
      <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
        <div data-testid="MemberListcmp" className="roster-editor-form">
          <Row>
            <Col>
              <MemberListFilter
                committeeClassificationList={committeeClassificationList}
                voteList={voteList}
                isFilterApplied={isFilterApplied}
              />
            </Col>
          </Row>
          {!isFilterApplied ? null : (
            <div className="adv-filter-tag">
              {map(appliedFilter, (filterObj, index) => {
                return (
                  <div
                    className="filter-tag"
                    key={filterObj.value + index}
                  >
                    <span data-testid="filteredValue">{filterObj.value}</span>{" "}
                    <i name="close" data-testid="removeFilter" className="astm-icon far fa-times" onClick={() => { this.removeFilter(filterObj); }}></i>
                  </div>
                );
              })}
            </div>
          )}
          <Row>
            <Col>
              <MemberListTable {...updatedProps} />
            </Col>
          </Row>
        </div>
      </MediaQuery>
      <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
        <div className="accordion-filter-section clearfix">
          <MemberListFilter
            committeeClassificationList={committeeClassificationList}
            voteList={voteList}
            isFilterApplied={isFilterApplied}
          />
        </div>
        <MemberListTable {...updatedProps} />
      </MediaQuery>
    </>
  }
}

const mapStateToProps = state => ({
  filters: state.filters
});

const mapDispatchToProps = dispatch => ({
  setAdvanceSearch: advanceSearchObj =>
    dispatch(setAdvanceSearch(advanceSearchObj))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberListComponent);
