import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import TextArea from '../atoms/RenderTextarea';
import CustomLoader from '../atoms/CustomLoader';
import { fetchComitteeMemberDetail, updateRosterNotes } from "../../services/rosterService";
import { setMemberId, setCurrentPopupOrigin } from "../../redux/roster/actions";
import types from "../../redux/roster/types";
import { memberNameFormat, hideApplicationScroll, focusOnPopupAndTabNavigationObject } from "../../helpers/utilCommon";
import MemberContactInfoComponent from "./MemberContactInformation";
import MemberClassificationInformationComponent from "./MemberClassificationInformation";
import OtherCommitteeListComponent from "./OtherCommitteeListTable";
import MemberCardPDF from "./MemberCardPDF";
import { IdentityDelegationButton } from "../atoms/IdentityDelegationButton";

let innerWidth = false;

class MemberCardPopUpComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayPDFCalloutBox: false,
      isMemberCardLoading: true,
      rosterNotes: {
        editRosterNotes: false,
        updatedRosterNote: "",
        isLoading: false
      }
    };
    this.popupModal = React.createRef();
  }

  displayPDFCalloutBox = () => {
    this.setState(
      {
        displayPDFCalloutBox: !this.state.displayPDFCalloutBox
      },
      () => {
        this.props.setPDFLoaderFlag(this.state.displayPDFCalloutBox);
      }
    );
  };


  mountKeyHandlerEvent = () => {
    if (this.popupModal)
      this.popupModal.current.addEventListener('keydown', (e) => focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.userprofile-popup', 'button'));
  }

  unmountKeyHandlerEvent = () => {
    if (this.popupModal)
      this.popupModal.current.removeEventListener('keydown', (e) => focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.userprofile-popup', 'button'));
  }


  setFocusEventHandler = () => {
    focusOnPopupAndTabNavigationObject.focusOnOpenHandler('.details-popup-outer .closePopup');
  }

  componentWillUnmount() {
    this.unmountKeyHandlerEvent();
  }

  componentDidMount = () => {
    if (window.isMobile) {
      hideApplicationScroll(true, 'member-card-popup');
    }
    this.props.updateMemberId(this.props.memberId);
    this.props.updatePopupOrigin(this.props.origin);
    this.setState({
      isMemberCardLoading: true
    }, () => {
      this.props.fetchMemberCardDetail(this.props.committeeId, this.props.memberId, (errMsg, memberCardDetail) => {
        this.setState({ memberCardDetail, isMemberCardLoading: false });
      });
      this.updatePopupContentHeight();
    });
    this.mountKeyHandlerEvent();
    this.setFocusEventHandler();
  };

  handleOrientation = () => {
    if (innerWidth !== window.innerWidth) this.updatePopupContentHeight();
    innerWidth = window.innerWidth;
  }

  updatePopupContentHeight() {
    if (window.isMobile) {
      let popupContents = document.getElementsByClassName("popup-content");
      if (popupContents.length > 0) {
        popupContents[0].style.height = (window.innerHeight - 127) + 'px';
      }
      window.checkWindowResize(this.handleOrientation);
    }
  }

  handleClosePopup = () => {
    hideApplicationScroll(false, 'member-card-popup');
    this.props.onClose(false, null, null, null);
    focusOnPopupAndTabNavigationObject.focusOnCloseHandler('.logoWrap a');
  };

  handleRosterNote = data => {
    this.setState({
      rosterNotes: {
        updatedRosterNote: data.target.value,
        editRosterNotes: true,
        isLoading: false
      }
    });
  };

  handleCancelRosterNotes = () => {
    this.setState({
      rosterNotes: {
        updatedRosterNote: "",
        editRosterNotes: false,
        isLoading: false
      }
    });
    // this.handleClosePopup();
  };

  handleRosterNoteUpdate = (e) => {
    e.preventDefault();
    const { rosterNotes } = this.state;
    const { updatedRosterNote } = rosterNotes;
    this.setState(
      {
        isMemberCardLoading: true,
        rosterNotes: {
          updatedRosterNote: updatedRosterNote,
          editRosterNotes: true,
          isLoading: true
        }
      },
      () => {
        const { committeeId, memberId } = this.props;
        const data = {
          committeeId,
          memberId,
          rosterNotes: updatedRosterNote
        };
        this.props.updateRosterNotes(data, (errMsg, success) => {
          if (success) {
            this.setState({
              rosterNotes: {
                updatedRosterNote: "",
                editRosterNotes: false,
                isLoading: false
              }
            });
            this.setState({
              isMemberCardLoading: true
            }, () => {
              this.props.fetchMemberCardDetail(committeeId, memberId, (errMsg, memberCardDetail) => {
                this.setState({ memberCardDetail, isMemberCardLoading: false });
              })
            });
          } else {
            this.setState({ isMemberCardLoading: false });
            this.handleCancelRosterNotes();
          }
        });
      }
    );
  };

  checkIsMainComittee() {
    const { selectedCommittee, committeeList } = this.props;
    let selectedComitteeDesignation = false;
    if (committeeList && committeeList.length > 0)
      for (let i = 0; i < committeeList.length; i++) {
        if (committeeList[i].committeeid === selectedCommittee) {
          selectedComitteeDesignation = committeeList[i].code;
          break;
        }
      }
    let isMainComittee =
      selectedComitteeDesignation && selectedComitteeDesignation.indexOf(".") === -1 ? true : false;
    return isMainComittee;
  }

  shouldComponentUpdate(newProps) {
    if (
      this.props.committeeId !== newProps.committeeId ||
      this.props.memberId !== newProps.memberId
    ) {
      newProps.updateMemberId(newProps.memberId);
      this.setState({
        isMemberCardLoading: true
      }, () => {
        newProps.fetchMemberCardDetail(newProps.committeeId, newProps.memberId, (errMsg, memberCardDetail) => {
          if(memberCardDetail)
          this.setState({ memberCardDetail, isMemberCardLoading: false });
        });
      });
      this.setState({
        rosterNotes: {
          editRosterNotes: false,
          updatedRosterNote: "",
          isLoading: false
        }
      });
    } else {
      if (newProps.currentMemberId !== null &&
        newProps.currentPopupOrigin !== null) {
        newProps.contentUpdated(
          this.props.committeeId,
          newProps.currentMemberId,
          newProps.currentPopupOrigin
        );
      }
    }
    if (
      newProps.memberCardDetail != null &&
      newProps.memberCardDetail.length > 0 &&
      newProps.memberCardDetail[0].memberid !== this.props.currentMemberId
    ) {
      this.setState({
        rosterNotes: {
          editRosterNotes: false,
          updatedRosterNote: "",
          isLoading: false
        }
      });
    }
    return true;
  }

  render() {
    const rosterNotesMaxLength=1000;
    const {
      selectedCommitteeWithPermission,
      leftPosition,
      topPosition,
      isPendingPopup
    } = this.props;
    const { memberCardDetail, isMemberCardLoading } = this.state;
    const {
      updateClassification,
      updateVoting
    } = selectedCommitteeWithPermission;
    const isMainComittee = this.checkIsMainComittee();
    const { rosterNotes } = this.state;
    const { editRosterNotes, updatedRosterNote, isLoading } = rosterNotes;
    let nameFormat = "";
    let memberDetails = [];
    let memberContactDetail = {};
    let memberClassfication = {};

    if (!isMemberCardLoading && memberCardDetail && memberCardDetail.length > 0) {
      memberDetails = memberCardDetail[0];
      nameFormat = memberNameFormat(
        memberDetails.firstname,
        memberDetails.middlename,
        memberDetails.lastname,
        2
      );
      memberContactDetail = {
        ...memberDetails.contact,
        email: memberDetails.email,
        organizationname: memberDetails.organizationname
      };
      memberClassfication = memberDetails.classification;
    }

    let popupClass =
      "details-popup-outer" + (isPendingPopup ? " sticky-popup" : "");
    let popupStyle = {};
    let arrowStyle = {};
    if (!isPendingPopup) {
      popupStyle = { left: leftPosition, top: topPosition };
    } else {
      popupStyle = { left: leftPosition };
      arrowStyle = { top: topPosition };
    }

    return (
      <div data-testid="MembercardPopupCmp" ref={this.popupModal} className={popupClass} style={popupStyle}>
        <span className="arrow-position" style={arrowStyle}></span>
        {isMemberCardLoading ? (
          <div className="userprofile-popup">
            <div className="popup-head noBorder">
              <button
                data-testid="handleClosePopupBtn"
                title="Close"
                className="closePopup floatRight"
                onClick={this.handleClosePopup}
              >
                <i aria-hidden="true" aria-label="close button" className="astm-icon far fa-times"></i>
              </button>
            </div>
            <div className="popup-content">
              <Row
                className="popup-loader-view txtCenter vMiddle"
                centered="true"
                columns={1}
              >
                <Col>
                  <CustomLoader isVisible={false} />
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="userprofile-popup">
            <div className="popup-head">
              {nameFormat}{" "}
              <button
                data-testid="handleClosePopupBtn"
                title="Close"
                className="closePopup floatRight"
                onClick={this.handleClosePopup}
              >
                <i aria-hidden="true" aria-label="close button" className="astm-icon far fa-times"></i>
              </button>
            </div>
            <div className="popup-content">
              {/* Contact section start */}
              <div className="popup-subhead">Contact Information</div>
              <MemberContactInfoComponent
                memberContactDetail={memberContactDetail}
              />
              {/* Classification section one start */}
              <div className="popup-subhead">Classification Information</div>
              <MemberClassificationInformationComponent
                memberClassfication={memberClassfication}
              />

              {/* section two start */}
              <div className="popup-subhead">Other Committees Information</div>
              <OtherCommitteeListComponent
                otherCommitteeList={memberDetails.othercommitteinfo}
              />

              {/* section three start */}
              <div className="popup-subhead">
                Main Committee Roster Notes &nbsp;
                <OverlayTrigger key={'right'} placement={'top'} overlay={<Tooltip id={`tooltip-top`}>{'Roster notes allow main committee officers the ability to clarify classification and vote assignments. These notes may  not always be applicable to a subcommittee classification or vote.'}</Tooltip>} >
                  <i aria-hidden="true" className="fas fa-info-circle"></i>
                </OverlayTrigger>

                {updateClassification && updateVoting && isMainComittee && !editRosterNotes ? (
                  <OverlayTrigger key={'left'} placement={'left'} overlay={<Tooltip id={`tooltip-top-left`}>{memberDetails.rosternotes ? "Edit Note" : "Add Note"}</Tooltip>} >

                    <button type="button" className="btn astm-btn astm-btn--small btn-outline-secondary floatRight" aria-label="Add Note"
                      onClick={() => {
                        this.setState({
                          rosterNotes: {
                            updatedRosterNote: memberDetails.rosternotes ? memberDetails.rosternotes : "",
                            editRosterNotes: true,
                            isLoading: false
                          }
                        });
                      }}
                    >
                      Add Note
                    </button>
                  </OverlayTrigger>
                ) : null}
              </div>

              <div className="popup-subhead-content">
                {editRosterNotes ? (
                  <>
                    <div className="note-form">
                      <TextArea
                        placeholder="Add Note..."
                        style={{ minHeight: 120 }}
                        value={updatedRosterNote}
                        onChange={this.handleRosterNote}
                        maxLength={rosterNotesMaxLength}
                      />
                      <div>
                      <span style={{ paddingTop: '5px', float: 'right', color: updatedRosterNote && updatedRosterNote.length == rosterNotesMaxLength ? '#941f29' : '#232f3a' }}>Character left: {updatedRosterNote ? rosterNotesMaxLength-updatedRosterNote.length : rosterNotesMaxLength}</span>
                      </div>
                      <div className="note-actions">
                        <button className="btn astm-btn btn-light" onClick={this.handleCancelRosterNotes}>Cancel</button>
                        <IdentityDelegationButton className={isLoading ? "btn astm-btn btn-secondary loading ml10" : "btn  btn-secondary ml10 idt-del-btn"} onClick={(e) => { this.handleRosterNoteUpdate(e) }}>Save</IdentityDelegationButton>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {memberDetails.rosternotes ? (
                      <div className="note-form">
                        {memberDetails.rosternotes}
                      </div>
                    ) : (
                      <div className="notes-content mb20">
                        <div className="no-notes">No notes available.</div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="popup-footer txtRight">
              <Button variant="secondary" loading={this.props.pdfLoaderFlag.toString()} onClick={this.displayPDFCalloutBox} className="btn astm-btn btn-secondary">Download Application</Button>
            </div>
          </div>
        )}
        {this.state.displayPDFCalloutBox ? (
          <MemberCardPDF propsData={{ ...this.props, memberCardDetail }} displayPDFCalloutBox={this.displayPDFCalloutBox} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { roster } = state;

  return {
    memberCardDetail: roster.memberCardDetail,
    currentMemberId: roster.currentMemberId,
    currentPopupOrigin: roster.currentPopupOrigin,
    pdfLoaderFlag: roster.pdfLoaderFlag,
    selectedCommittee: roster.selectedCommittee,
    committeeList: roster.committeeList
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMemberCardDetail: (committeeid, memberid, cb) => fetchComitteeMemberDetail({ committeeid, memberid }, cb),
  updateMemberId: memberid => dispatch(setMemberId(memberid)),
  updatePopupOrigin: origin => dispatch(setCurrentPopupOrigin(origin)),
  setPDFLoaderFlag: flag => dispatch({ type: types.SET_PDF_LOADER, pdfLoaderFlag: flag }),
  updateRosterNotes: (data, callback) => updateRosterNotes(data, dispatch, callback)
});

const MemberCardPopUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberCardPopUpComponent);

export default MemberCardPopUp;
