import React from 'react';
import DateInput from 'react-datepicker' //Docs https://reactdatepicker.com/
import { assign } from 'lodash';
import { useMediaQuery } from "react-responsive";
import { maxWidthValue } from "../../helpers/constant.js";

const DatePicker = ({ changeHandler, handleOnBlur, isKeyBoardInput, error, label, id, blockLabel, placeholder, value, dateFormat, className, minDate, maxDate, readOnly, ...restProps }) => {
    const customInputs = assign(restProps, {
        selected: value ? new Date(value) : '',
        dateFormat: dateFormat ? dateFormat : 'MM/dd/yyyy',
        placeholderText: placeholder ? placeholder : 'MM/DD/YYYY',
        className: error ? `${className ? className : ''} error` : className ? className : '',
        minDate: minDate ? minDate : null,
        maxDate: maxDate ? maxDate : null,
        onChange: (value) => changeHandler && changeHandler(value),
        onBlur: ({ target: { value } }) => handleOnBlur && handleOnBlur(value),
        onChangeRaw: (event) => isKeyBoardInput ? '' : event.preventDefault(),
        readOnly: readOnly ? readOnly : false,
        peekNextMonth: true,
        showMonthDropdown: true,
        showYearDropdown: true,
        // showPopperArrow: false,
        dropdownMode: 'select',
        isClearable: true
    });

    const isMobile = useMediaQuery({ maxWidth: maxWidthValue.tabletOrMobile })

    return (
        <React.Fragment>
            {label && <label htmlFor={id} className={blockLabel ? 'block' : 'inlineBlock mr10'}>{label}</label>}

            <div data-testid="DatePickerCmp" className="datepickerDV">
                <label>
                    {isMobile ? <DateInput id={id} {...customInputs} withPortal /> : <DateInput id={id} {...customInputs} />}
                    <i className="astm-icon far fa-calendar-alt"></i>
                </label>

            </div>
        </React.Fragment>
    );
}
export default DatePicker;