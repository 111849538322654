import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, OverlayTrigger, Popover, Dropdown } from 'react-bootstrap';
import { map, findIndex, assign, countBy, isEqual } from 'lodash';
import RenderCheckbox from '../atoms/Checkbox';
import RenderSearchInputBox from '../atoms/SearchInputBox';
import Tooltip from '../atoms/Tooltip';
import { setClassFilter, setVoteFilter, setBasicSearch } from '../../redux/filterValues/actions'
import MemberListAdvanceSearch from './MemberListAdvanceSearch';
import { focusOnPopupAndTabNavigationObject } from '../../helpers/utilCommon';

class MemberListFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classFilter: props.classFilter,
            voteFilter: props.voteFilter,
            basicSearch: props.basicSearchText,
            isAllClassChecked: props.isAllClassChecked,
            isAllVoteChecked: props.isAllVoteChecked,
            classCount: props.classCount,
            voteCount: props.voteCount,
            isAdvanceSearchModal: false,
            activeDropDownRef: null
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { classFilter, voteFilter, isAllClassChecked, isAllVoteChecked, classCount, voteCount } = nextProps;
        if (!(isEqual(classFilter, prevState.classFilter) &&
            isEqual(voteFilter, prevState.voteFilter) &&
            isAllClassChecked === prevState.isAllClassChecked &&
            isAllVoteChecked === prevState.isAllVoteChecked &&
            classCount === prevState.classCount &&
            voteCount === prevState.voteCount
        )) {
            return {
                classFilter,
                voteFilter,
                isAllClassChecked,
                isAllVoteChecked,
                classCount,
                voteCount
            };
        }
        return null;
    }

    handleAllChecked = (type) => {
        let setObj = {};
        if (type === "class") {
            let { classFilter, isAllClassChecked } = this.state;
            if (!isAllClassChecked) {
                setObj.isAllClassChecked = !isAllClassChecked;
                let updatedClassFilter = map(classFilter, (obj) => {
                    return assign(obj, { checked: true });
                });
                setObj.classFilter = updatedClassFilter;
            }
        } else {
            let { voteFilter, isAllVoteChecked } = this.state;
            if (!isAllVoteChecked) {
                setObj.isAllVoteChecked = !isAllVoteChecked;
                let updatedVoteFilter = map(voteFilter, (obj) => {
                    return assign(obj, { checked: true });
                });
                setObj.voteFilter = updatedVoteFilter;
            }
        }
        this.setState(setObj, () => {
            this.setFilters(type);
        });
    }

    handleToggleClass = (key) => {
        let { classFilter } = this.state;
        const index = findIndex(classFilter, { key });
        const oldValue = classFilter[index].checked;
        classFilter[index].checked = !oldValue;
        let setObj = {
            classFilter
        };
        if (oldValue) {
            setObj.isAllClassChecked = false;
        } else {
            const falseIndex = findIndex(classFilter, { checked: false });
            if (falseIndex === -1) {
                setObj.isAllClassChecked = true;
            }
        }
        this.setState(setObj, () => {
            this.setFilters('class');
        });
    }

    handleToggleVote = (key) => {
        let { voteFilter } = this.state;
        const index = findIndex(voteFilter, { key });
        const oldValue = voteFilter[index].checked;
        voteFilter[index].checked = !oldValue;
        let setObj = {
            voteFilter
        };
        if (oldValue) {
            setObj.isAllVoteChecked = false;
        } else {
            const falseIndex = findIndex(voteFilter, { checked: false });
            if (falseIndex === -1) {
                setObj.isAllVoteChecked = true;
            }
        }
        this.setState(setObj, () => {
            this.setFilters('vote');
        });
    }

    setFilters = (type) => {
        if (type === 'class') {
            const { classFilter } = this.state;
            const { setClassFilter } = this.props;
            const classCount = countBy(classFilter, 'checked');
            const trueCount = classCount.true ? classCount.true : 0;
            this.setState({ classCount: trueCount });
            setClassFilter(classFilter);
        } else {
            const { voteFilter } = this.state;
            const { setVoteFilter } = this.props;
            const voteCount = countBy(voteFilter, 'checked');
            const trueCount = voteCount.true ? voteCount.true : 0;
            this.setState({ voteCount: trueCount });
            setVoteFilter(voteFilter);
        }
    }

    handleBasicSearchInput = (e) => this.setState({ [e.target.name]: e.target.value });

    onClickSearch = (e) => {
        e.preventDefault();
        const { basicSearch } = this.state;
        const search = basicSearch.trim();
        if (search.length != 1) {
            this.props.setBasicSearch(search);
        }
    }

    onClickReset = (e) => {
        e.preventDefault();
        this.handleAllChecked('class');
        this.handleAllChecked('vote');
        this.setState({ basicSearch: '' });
        this.props.setBasicSearch(null);
    }

    renderAdvanceSearch = () => (
        <Tooltip
            content='Advanced Search'
            placement='top'
            target={
                <Button variant="light" className="astm-btn adv-search-active" data-testid="AdvancedSearchBtn" aria-label="advance search" onClick={() => { this.setState({ isAdvanceSearchModal: true }); }}>
                    {/* <i class="far fa-sliders-v"></i> */}
                    <i className="fas fa-sliders-h astm-icon"></i>
                    {this.props.isFilterApplied ? <i className="filterApplied" /> : null}

                </Button>
            }
        />


    )

    handleCloseSearchModal = () => {
        this.setState({ isAdvanceSearchModal: false });
    }

    focusOnClickClassificationHandler = () => {
        focusOnPopupAndTabNavigationObject.focusOnOpenHandler('.bs-popover-bottom .dropdown-item');
        this.setState({
            activeDropDownRef: '#classification'
        })
    }

    focusOnClickVotesHandler = () => {
        focusOnPopupAndTabNavigationObject.focusOnOpenHandler('.bs-popover-bottom .dropdown-item');
        this.setState({
            activeDropDownRef: '#vote'
        })
    }


    escapeKeyPressDropDownHandler = (e) => {
        if (e && e.keyCode === 27) {
            const isDropDownListVisible = document.querySelector('.bs-popover-bottom .dropdown-item');
            if (isDropDownListVisible) {
                focusOnPopupAndTabNavigationObject.focusOnCloseHandler(this.state.activeDropDownRef)
            }
        }
    }

    mountKeyHandlerEvent = () => {
        window.addEventListener('keydown', (e) => {
            this.escapeKeyPressDropDownHandler(e);
            focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.bs-popover-bottom', '.dropdown-item')
        });
    }

    unmountKeyHandlerEvent = () => {
        window.removeEventListener('keydown', (e) => {
            focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.bs-popover-bottom', '.dropdown-item')
        });
    }

    componentDidMount() {
        this.mountKeyHandlerEvent();
    }

    componentWillUnmount() {
        this.unmountKeyHandlerEvent();
    }

    render() {

        let {
            classFilter,
            voteFilter,
            isAllClassChecked,
            isAllVoteChecked,
            classCount,
            voteCount,
            basicSearch,
            isAdvanceSearchModal
        } = this.state;

        let { isFilterApplied } = this.props;
        return (
            <div data-testid="MemberListFilterCmp" >
                <div className={"rosterFilter clearfix" + (isFilterApplied ? " adv-inactive" : "") + (isAdvanceSearchModal ? ' hide' : '')}>
                    <div className="filterDV">
                        <div ref={this.allClassificationRef} data-testid="PopoverClick">
                            <OverlayTrigger rootClose={true} trigger="click" placement={'bottom-start'}
                                overlay={
                                    <Popover className="filter-option-menu">
                                        <Dropdown.Item as="button">
                                            <RenderCheckbox role="checkbox"
                                                name={'allClassifications'}
                                                label='All Classifications'
                                                onChange={() => { this.handleAllChecked('class'); }}
                                                checked={isAllClassChecked}
                                            />
                                        </Dropdown.Item>

                                        {map(classFilter, (classObj) => (
                                            <Dropdown.Item as="button" key={classObj.key}>
                                                <RenderCheckbox role="checkbox"
                                                    name={classObj.label}
                                                    label={classObj.label}
                                                    onChange={() => { this.handleToggleClass(classObj.key); }}
                                                    checked={classObj.checked}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                    </Popover>
                                }
                            >
                                <Dropdown className="astm-dropdown">
                                    {/* <Dropdown.Toggle variant="outline-secondary" className="astm-btn">{isAllClassChecked || isFilterApplied ? 'All ' : (<span>({classCount})</span>)} Classifications</Dropdown.Toggle> */}
                                    <button
                                        data-testid="ClickClassificationHandler"
                                        disabled={isFilterApplied}
                                        type="button"
                                        className="btn astm-btn btn-light dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                        onClick={this.focusOnClickClassificationHandler}
                                        id="classification"
                                    >
                                        {isAllClassChecked || isFilterApplied ? 'All ' : (<span>({classCount})</span>)} Classifications
                                    </button>
                                </Dropdown>
                            </OverlayTrigger>
                        </div>

                        <div>
                            <OverlayTrigger rootClose={true} trigger="click" placement={'bottom-start'}
                                overlay={
                                    <Popover className="filter-option-menu">
                                        <Dropdown.Item as="button">
                                            <RenderCheckbox role="checkbox"
                                                name={'allVotes'}
                                                label='All Votes'
                                                onChange={() => { this.handleAllChecked('vote'); }}
                                                checked={isAllVoteChecked}
                                            />
                                        </Dropdown.Item>

                                        {map(voteFilter, (voteObj) => (
                                            <Dropdown.Item as="button" key={voteObj.key}>
                                                <RenderCheckbox role="checkbox"
                                                    name={voteObj.label}
                                                    label={voteObj.label}
                                                    onChange={() => { this.handleToggleVote(voteObj.key); }}
                                                    checked={voteObj.checked}
                                                />
                                            </Dropdown.Item>
                                        ))}
                                    </Popover>
                                }
                            >
                                <Dropdown className="astm-dropdown">
                                    {/* <Dropdown.Toggle variant="outline-secondary" className="astm-btn">{isAllVoteChecked || isFilterApplied ? 'All ' : (<span>({voteCount})</span>)} Votes</Dropdown.Toggle> */}
                                    <button
                                        data-testid="ClickVotesHandler"
                                        disabled={isFilterApplied}
                                        type="button"
                                        className="btn astm-btn btn-light dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        onClick={this.focusOnClickVotesHandler}
                                        id="vote"
                                    >
                                        {isAllVoteChecked || isFilterApplied ? 'All ' : (<span>({voteCount})</span>)} Votes
                                    </button>
                                </Dropdown>
                            </OverlayTrigger>
                        </div>

                        <div>
                            <div className="filter-search">
                                <RenderSearchInputBox
                                    placeholder="Search"
                                    name="basicSearch"
                                    id="basicSearch"
                                    value={basicSearch}
                                    onChange={this.handleBasicSearchInput}
                                    onClickSearch={this.onClickSearch}
                                    onKeyUp={(e) => { (e.keyCode === 13) && this.onClickSearch(e) }}
                                    disabled={isFilterApplied}
                                />
                            </div>
                        </div>


                        <div>
                            {this.renderAdvanceSearch()}
                        </div>

                        <div>
                            <Tooltip
                                content='Reset'
                                placement='top'
                                target={
                                    <Button data-testid="ClickReset" disabled={isFilterApplied} variant="light" aria-label="reset" onClick={this.onClickReset}>
                                        <i className="fas fa-sync"></i>
                                    </Button>
                                }
                            />
                        </div>
                    </div>
                </div>
                {isAdvanceSearchModal ? (
                    <MemberListAdvanceSearch onClose={this.handleCloseSearchModal} />
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setClassFilter: classFilter => dispatch(setClassFilter(classFilter)),
    setVoteFilter: voteFilter => dispatch(setVoteFilter(voteFilter)),
    setBasicSearch: voteFilter => dispatch(setBasicSearch(voteFilter))
});

const mapStateToProps = state => {
    const { classFilter, voteFilter, basicSearchText } = state.filters
    const falseClassIndex = findIndex(classFilter, { checked: false });
    const falseVoteIndex = findIndex(voteFilter, { checked: false });
    const classCount = countBy(classFilter, 'checked');
    const voteCount = countBy(voteFilter, 'checked');
    return ({
        classFilter,
        voteFilter,
        basicSearchText,
        isAllClassChecked: falseClassIndex === -1 ? true : false,
        isAllVoteChecked: falseVoteIndex === -1 ? true : false,
        classCount: classCount.true ? classCount.true : 0,
        voteCount: voteCount.true ? voteCount.true : 0,
    });
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberListFilterComponent);