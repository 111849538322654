export const UPDATE_PENDING_APPLICATION = 'UPDATE_PENDING_APPLICATION';
export const SET_ALL_COMMITTEE_LIST = 'SET_ALL_COMMITTEE_LIST';
export const SET_SELECTED_COMMITTEE = 'SET_SELECTED_COMMITTEE';
export const SHOW_SUMMARY_LOADER = 'SHOW_SUMMARY_LOADER';
export const HIDE_SUMMARY_LOADER = 'HIDE_SUMMARY_LOADER';
export const SET_COMMITTEE_SUMMARY = 'SET_COMMITTEE_SUMMARY';
export const SHOW_MEMBER_LOADER = 'SHOW_MEMBER_LOADER';
export const SET_MEMBER_LIST = 'SET_MEMBER_LIST';
export const SHOW_MEMBER_CARD_LOADER = 'SHOW_MEMBER_CARD_LOADER';
export const HIDE_MEMBER_LOADER = 'HIDE_MEMBER_LOADER';
export const SET_MEMBER_ID = 'SET_MEMBER_ID';
export const SET_CURRENT_POPUP_ORIGIN = 'SET_CURRENT_POPUP_ORIGIN';
export const SET_CLASS_LIST_LOADER = 'SET_CLASS_LIST_LOADER';
export const SET_VOTE_LIST_LOADER = 'SET_VOTE_LIST_LOADER';
export const SET_COMMITTEE_CLASSIFICATION_LIST = 'SET_COMMITTEE_CLASSIFICATION_LIST';
export const SET_VOTE_LIST = 'SET_VOTE_LIST';
export const SET_ROSTER_REPORT = 'SET_ROSTER_REPORT';
export const SET_ROSTER_EXCEL_DATA = 'SET_ROSTER_EXCEL_DATA';
export const SET_PDF_LOADER = 'SET_PDF_LOADER';

export default {
    UPDATE_PENDING_APPLICATION,
    SET_ALL_COMMITTEE_LIST,
    SET_SELECTED_COMMITTEE,
    SHOW_SUMMARY_LOADER,
    HIDE_SUMMARY_LOADER,
    SET_COMMITTEE_SUMMARY,
    SHOW_MEMBER_LOADER,
    HIDE_MEMBER_LOADER,
    SET_MEMBER_LIST,
    SHOW_MEMBER_CARD_LOADER,
    SET_MEMBER_ID,
    SET_CLASS_LIST_LOADER,
    SET_VOTE_LIST_LOADER,
    SET_COMMITTEE_CLASSIFICATION_LIST,
    SET_VOTE_LIST,
    SET_ROSTER_REPORT,
    SET_ROSTER_EXCEL_DATA,
    SET_PDF_LOADER,
    SET_CURRENT_POPUP_ORIGIN
}