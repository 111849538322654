import * as type from './types';

const initialState = {
  code: null,
  showErrorPopup: false,
  uuidNumber: null
};

const reducerShared = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_MESSAGE:
      return {
        ...state,
        code: action.code,
        showToastMessage: action.flag,
        uuidNumber: action.uuidNumber ? action.uuidNumber : null
      };

    default:
      return state;
  }
};

export default reducerShared;