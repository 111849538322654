import { find, isEmpty, map } from 'lodash';
import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';

class SortByMobile extends Component {

    handleSortChange = (index) => {
        const { headerProps } = this.props;
        let { sortKey, orderBy, thirdClickOff } = headerProps[index];
        let thirdClickValue = thirdClickOff ? 0 : 1;
        orderBy = (orderBy > 1) ? thirdClickValue : orderBy + 1;
        this.props.onClickHeader(sortKey, orderBy);
    }

    render() {
        const { headerProps, label } = this.props;
        let currentlySortedField = find(headerProps, (obj) => obj.orderBy > 0);
        if (isEmpty(currentlySortedField)) {
            currentlySortedField = {
                title: 'Default',
                orderBy: 0
            }
        }

        let sortTitle = currentlySortedField.title
        if (currentlySortedField.orderBy === 1) {
            sortTitle += ' (Ascending)';

        } else if (currentlySortedField.orderBy === 2) {
            sortTitle += ' (Descending)';
        }

        return (
            <div data-testid="mobileHeaderSort" className='mobileHeaderSort'>
                <div className="sort-label bold">{label}</div>
                <Dropdown className="sort-dropdown" data-testid="headerMenu">
                    <Dropdown.Toggle as='div' className='custom-select form-control' >
                        <span className="sortTitle ellip">{sortTitle}</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu data-testid="DropdownMenu">
                        {map(headerProps, (headerObj, index) => {
                            if (!headerObj.hasSorting) {
                                return null;
                            }
                            return (
                                <Dropdown.Item 
                                    as='span'
                                    role="menuitem"
                                    key={headerObj.key}
                                    onClick={() => this.handleSortChange(index)}
                                >{headerObj.title}</Dropdown.Item>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

export default SortByMobile;