import React from 'react';
import { Accordion, useAccordionToggle } from 'react-bootstrap';

const CustomToggle = ({ eventKey, selectedAccordianKey, setAccordianKey, collapseChild, expendChild, ...otherProps }) => {

    const customOnClick = useAccordionToggle(eventKey, () => {
        let setKey = eventKey;

        if (eventKey === selectedAccordianKey) {
            setKey = null;
        }
        if(setAccordianKey)
        setAccordianKey(setKey);
    });

    const child = (eventKey === selectedAccordianKey) ? expendChild : collapseChild;
    return (
        <Accordion.Toggle {...otherProps} onClick={customOnClick} eventKey="StatsTable" data-testid="customToggleCmp">
            {child}
        </Accordion.Toggle>
    );
}

export default CustomToggle;
