const en = {
	// Error Message Example
	'401': { type: 'danger', text: 'Your session has expired, please login again.' },
	'402': { type: 'danger', text: 'There seems to be a momentary issue. Please refresh or try again after sometime.' },
	
	// Classification Update
	'0001': { type: 'success', text: 'Member Classification Updated Successfully.' },
	'0002': { type: 'danger', text: 'Member Classification Update Unsuccessful.' },

	// Official Vote Update
	'0003': { type: 'success', text: 'Member Official Vote Updated Successfully.' },

	// Dates Error
	'1001': { type: 'danger', text: 'Incorrect input' },
	'1002': { type: 'danger', text: "'From' date cannot be greater than 'To' date." },
	'1003': { type: 'danger', text: "'From' date cannot be greater than the current date." },
	'10010': { type: 'danger', text: "Please enter a 'Meeting Date'." },
	'10011': { type: 'danger', text: "'Start Date' cannot be greater than End Date." },
	'10012': { type: 'danger', text: "'Start Date' cannot be greater than current date." },
	'10013': { type: 'danger', text: "'Start Date' and 'End Date' is mandatory to download 'New Members', 'Members Changing Employment' and 'Members Removed from Roster' reports." },
	'10014': { type: 'danger', text: "Please select at least one report." },

	// Member card details update
	'2001': { type: 'success', text: 'All changes saved successfully' },

	// No data from API
	'4001': { type: 'danger', text: 'No Data Found' },
	'4002': { type: 'danger', text: 'No InActivity Report found for the selected Committee' },

}

export default en;