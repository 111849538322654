import { combineReducers } from 'redux';
import loginReducer from './redux/login/reducers';
import appReducer from './redux/app/reducers';
import rosterReducer from './redux/roster/reducers';
import reducerShared from './redux/shared/reducers';
import { i18nReducer } from 'redux-react-i18n';
import loaderReducer from './redux/editLoader/reducers';
import filterReducer from './redux/filterValues/reducers';

const rootReducer = combineReducers({
  user: loginReducer,
  app: appReducer,
  roster: rosterReducer,
  shared: reducerShared,
  i18nReducer,
  loaders: loaderReducer,
  filters: filterReducer
});

export default rootReducer;