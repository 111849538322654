import { isEmpty } from 'lodash';
import React from 'react';
import { Route } from 'react-router-dom';


export const ProtectedRosterRoute = ({ children: Children, ...rest }) => {
    const { userPermission } = rest;
    if (!isEmpty(userPermission)) {
        return (
            <Route {...rest}>
                {Children}
            </Route>
        )
    }
    return null;
};

