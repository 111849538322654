import React from 'react';
import { Form } from 'react-bootstrap';
import { assign } from 'lodash';

const RenderRadio = ({...otherProps}) => {

    let customProps = assign(otherProps, {
        id: otherProps.id ? otherProps.id : otherProps.name ? otherProps.name : "custom-radio",
        custom: otherProps.custom ? otherProps.custom : true,
        label: otherProps.label ? otherProps.label : '',
        type: 'radio',
        className: otherProps.className ? "astm-custom-radio " + otherProps.className : "astm-custom-radio"
    });

    return (
        <Form.Check data-testid="RadioBoxCmp" {...customProps}/>
    );
}

export default RenderRadio;