import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash'
import { DropdownList } from 'react-widgets' // Docs https://jquense.github.io/react-widgets/api/Multiselect/
import { searchByFirstName, searchByLastName } from '../../redux/filterValues/actions';

class NameSearchComponent extends Component {
    enterKeyPressCount = 0;
    constructor(props) {
        super(props);
        this.state = {
            hideSearch: false,
            focussed: false,
            typing: false,
            focusedSearchFieldName: '',
            isEmptyList: false
        };
    }

    componentDidMount() {
        this.props.resetFirstNameSearchQuery();
        this.props.resetLastNameSearchQuery();
        this.resetSearch();
    }

    resetSearch() {
        this.setState({
            hideSearch: true
        }, () => {
            if (this.state.hideSearch) {
                this.setState({
                    hideSearch: false
                });

            }
        });
    }

    handleOnChange = (data, actionEvent) => {
        if (actionEvent.originalEvent.code === 'ArrowUp' || actionEvent.originalEvent.code === 'ArrowDown') {
            return true;
        }
        this.setFocus(false);
        const { searchField } = this.props;
        this.props.changeHandler(data);
        if (actionEvent.originalEvent.keyCode !== 13) {
            this.enterKeyPressCount = 1;
        }
        switch (searchField) {
            case 'firstname':
                this.props.resetFirstNameSearchQuery();
                break;
            case 'lastname':
                this.props.resetLastNameSearchQuery();
                break;
            default:
                break;
        }
        this.setState({
            typing: false
        });
    }

    handleSearchChange = (data) => {
        const { membersList, searchField } = this.props;
        if (searchField === this.state.focusedSearchFieldName) {
            this.setState({ isEmptyList: true });
        }

        switch (searchField) {
            case 'firstname':
                    if (membersList && membersList.committeemembers && membersList.committeemembers.length > 0) {
                        this.props.fetchMemberListByFirstNameLocal(data, membersList.committeemembers)
                    }
                break;
            case 'lastname':
                    if (membersList && membersList.committeemembers && membersList.committeemembers.length > 0) {
                        this.props.fetchMemberListByLastNameLocal(data, membersList.committeemembers)
                    }
                break;
            default:
                break;
        }
    }

    handleKeyUp = (event) => {
        if (!this.state.typing) {
            this.setState({
                typing: true
            });
        }
        if (event.keyCode === 13) {
            this.enterKeyPressCount++;
        } else {
            this.enterKeyPressCount = 0;
        }
        const { searchField, value, firstName, lastName } = this.props;
        let filterData = {};
        switch (searchField) {
            case 'firstname':
                filterData = firstName;
                break;
            case 'lastname':
                filterData = lastName;
                break;
            default:
                break;
        }
        const { search } = filterData;
        if (this.enterKeyPressCount === 2 && search === '' && value !== '') {
            this.enterKeyPressCount = 0;
            this.props.clickSearchHandler(value);
        }
    }

    modifyOptions(data, selectedValue) {
        let options = [];
        let newResults = [];
        const { searchField } = this.props;
        let { maxLength } = this.props;
        maxLength = maxLength ? maxLength : 10;
        const resultsCount = data && data.length > maxLength ? maxLength : data ? data.length : 0;
        for (let i = 0; i < resultsCount; i++) {
            options.push({
                key: data[i][searchField] + "_search_" + i,
                text: data[i][searchField],
                value: data[i][searchField]
            });
            newResults.push(data[i][searchField]);
        }
        if (selectedValue && newResults.indexOf(selectedValue) === -1) {
            options.push({
                key: selectedValue + "_selected",
                text: selectedValue,
                value: selectedValue
            });
        }
        let optionsList = options.map(item => item['text']);
        return optionsList;
    }

    componentDidUpdate() {
        this.handleConditionOfSetSelectedValueSearch();
    }

    handleConditionOfSetSelectedValueSearch() {
        const { value, searchField, firstName, lastName } = this.props;
        let filterData = {};
        switch (searchField) {
            case 'firstname':
                filterData = firstName;
                break;
            case 'lastname':
                filterData = lastName;
                break;
            default:
                break;
        }
        const { search } = filterData;
        if (value !== '') {
            if (search === '' && !this.state.typing) {
                const data = {
                    searchQuery: value
                };
                this.handleSearchChange(null, data);
                this.resetSearch();
                this.setFocus(false);
            }
        }
    }

    setFocus(focussed) {
        this.setState({
            focussed: focussed,
            focusedSearchFieldName: this.props.searchField
        });
    }

    handleOnBlur = (search) => {
        const { value } = this.props;
        this.setFocus(false);
        this.setState({
            typing: false
        });
        if (search !== '' && value !== '') {
            const { searchField } = this.props;
            switch (searchField) {
                case 'firstname':
                    this.props.resetFirstNameSearchQuery();
                    break;
                case 'lastname':
                    this.props.resetLastNameSearchQuery();
                    break;
                default:
                    break;
            }
        } else if (search === '' && value !== '') {
            this.props.changeHandler('');
        }
    }

    render() {
        const { label, placeholder, value, searchField, firstName, lastName } = this.props;
        let filterData = {};
        switch (searchField) {
            case 'firstname':
                filterData = firstName;
                break;
            case 'lastname':
                filterData = lastName;
                break;
            default:
                break;
        }
        const { search, results, isLoading } = filterData;
        const options = this.modifyOptions(results, value);
        let className = this.props.className ? this.props.className : '';
        if (value !== '') {
            // This class is added to differentiate between placeholder and selected text
            className += 'name-search-value-selected';
        }
        if (this.state.hideSearch)
            return null;
        return (
            <div data-testid="NameSearchCmp">
                {label && <label>{label}</label>}
                <DropdownList data-testid="DropdownListId"
                    filter={'contains'}
                    name={label}
                    busy={isLoading}
                    busySpinner={<i className="fas fa-sync fa-spin" />}
                    className={"dropdown-loader " + className}
                    placeholder={placeholder}
                    data={options}
                    value={value}
                    onChange={this.handleOnChange}
                    onKeyUp={this.handleKeyUp}
                    onFocus={() => this.setFocus(true)}
                    onBlur={() => this.handleOnBlur(search)}
                    messages={{ emptyList: (options.length === 0 && searchField == this.state.focusedSearchFieldName && this.state.isEmptyList) ? 'No results found' : '', emptyFilter: 'No results found' }}
                    onSearch={debounce(this.handleSearchChange, 500)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { roster, filters } = state;
    return {
        firstName: filters.firstName,
        lastName: filters.lastName,
        committeeid: roster.selectedCommittee,
        membersList: roster.memberList
    }
};

const mapDispatchToProps = dispatch => ({
    resetFirstNameSearchQuery: () => dispatch(searchByFirstName('', [], false)),
    fetchMemberListByFirstNameLocal: (firstName, memberList) => {
        dispatch(searchByFirstName(firstName, [], true));
        const firstNameArray = [];
        const newMemberList = memberList.filter(member => {
            const re = new RegExp(firstName, 'i');
            const isMatch = re.test(member.firstname);
            if (isMatch && firstNameArray.indexOf(member.firstname) < 0) {
                firstNameArray.push(member.firstname);
                return member;
            }
            return false;
        });
        dispatch(searchByFirstName(firstName, newMemberList, false));
    },
    resetLastNameSearchQuery: () => dispatch(searchByLastName('', [], false)),
    fetchMemberListByLastNameLocal: (lastName, memberList) => {
        dispatch(searchByLastName(lastName, [], true));
        const lastNameArray = [];
        const newMemberList = memberList.filter(member => {
            const re = new RegExp(lastName, 'i');
            const isMatch = re.test(member.lastname);
            if (isMatch && lastNameArray.indexOf(member.lastname) < 0) {
                lastNameArray.push(member.lastname);
                return member;
            }
            return false;
        });
        dispatch(searchByLastName(lastName, newMemberList, false));
    }
})

const NameSearch = connect(
    mapStateToProps,
    mapDispatchToProps
)(NameSearchComponent);

export default NameSearch;