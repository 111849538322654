import React, { Component } from 'react';
import { memberNameFormat } from '../../helpers/utilCommon';
import MemberContactInfoComponent from './MemberContactInformation';
import MemberClassificationInformationComponent from './MemberClassificationInformation';
import OtherCommitteeListComponent from './OtherCommitteeListTable';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

class MemberCardPDF extends Component {
    constructor(props) {
        super(props);

        const { memberCardDetail } = props.propsData;
        const memberDetails = memberCardDetail[0];
        this.state = {
            nameFormat: memberNameFormat(memberDetails.firstname, memberDetails.middlename, memberDetails.lastname)
        }
    }

    componentDidMount() {
        document.getElementById('userprofile-popupContent-pdf').style.height = '100%';
        let input = document.getElementById('userprofile-popup-pdf');
        html2canvas(input).then((canvas) => {
            const pdfObj = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });
            let margins = {
                left: 10,
                right: 20,
                top: 10
            }
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = pdfObj.internal.pageSize.getWidth() - margins.right;
            const imgHeight = canvas.height * (imgWidth / canvas.width);
            const pageHeight = 295;
            let heightLeft = imgHeight;

            pdfObj.addImage(imgData, 'PNG', margins.left, margins.top, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                margins.top = heightLeft - imgHeight;
                pdfObj.addPage();
                pdfObj.addImage(imgData, 'PNG', margins.left, margins.top, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }


            pdfObj.save(`${this.state.nameFormat}.pdf`);
            this.props.displayPDFCalloutBox();
        });
    }

    render() {
        const { memberCardDetail } = this.props.propsData;
        const { nameFormat } = this.state;
        const memberDetails = memberCardDetail[0];
        const memberClassfication = memberDetails.classification;
        const memberContactDetail = {
            ...memberDetails.contact,
            email: memberDetails.email,
            organizationname: memberDetails.organizationname
        };
        return (
            <>
                <div data-testid="MemberCardPDFCmp" className="userprofile-popup pdf-pop-up" id="userprofile-popup-pdf">
                    <div className="popup-head">{nameFormat}</div>
                    <div className="popup-content" id="userprofile-popupContent-pdf">
                        { /* Contact section start */}
                        <div className="popup-subhead">Contact Information</div>
                        <MemberContactInfoComponent memberContactDetail={memberContactDetail} />

                        {/* Classification section one start */}
                        <div className="popup-subhead">Classification Information</div>
                        <MemberClassificationInformationComponent memberClassfication={memberClassfication} />

                        {/* section two start */}
                        <div className="popup-subhead">Other Committees Information</div>
                        <OtherCommitteeListComponent otherCommitteeList={memberDetails.othercommitteinfo} isPDF={true} />

                        { /* section three start */}
                        <div className="popup-subhead">Main Committee Roster Notes </div>
                        <div className="popup-subhead-content">
                            { memberDetails.rosternotes ? (
                                <div className="notes-content">
                                    {memberDetails.rosternotes}
                                </div>
                            ) : (
                                <div className="no-notes">
                                    No notes available.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default MemberCardPDF
