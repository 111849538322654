import React from "react";
import { Spinner } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import SummaryChart from '../molecules/SummaryChart';
import SummaryAvailableVotes from '../molecules/SummaryAvailableVotes';
import SummaryStats from '../molecules/SummaryStats';
import SummaryStatsTable from '../molecules/SummaryStatsTable';

const ChartSummary = props => {
    const { isSummaryLoading, committeeSummary } = props;
    if (isSummaryLoading) {
        return (
            <div className="summar-loader-view">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Summary Loading</span>
                </Spinner>
            </div>
        );
    }
    if (isEmpty(committeeSummary)) {
        return (
            <span>No Summary Data is available.</span>
        );
    }
    const { committeeclassification, iscommitteeclassified, produceravailablevote, showavailablevote } = committeeSummary;
    const { classifications } = committeeclassification;
    return (
        <>
            <div data-testid="SummaryComponent" className="summary-heading">Committee Summary</div>
            <div className="chart-mobile-view">
                <SummaryChart committeeclassification={committeeclassification} />
                <SummaryAvailableVotes produceravailablevote={produceravailablevote} iscommitteeclassified={iscommitteeclassified} showavailablevote={showavailablevote} />
                <div className="totalSummary">
                    <SummaryStats committeeclassification={committeeclassification} />
                    <SummaryStatsTable classifications={classifications} />
                </div>
            </div>
        </>
    );
}
export default ChartSummary;
