import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { focusOnPopupAndTabNavigationObject } from '../../helpers/utilCommon';

class ConfirmationPopUp extends React.Component {

    handleConfirm = () => {
        const { data, confirmationType } = this.props;
        this.props.handleConfirm(data, confirmationType);
    }

    handleClosePopup = () => {
        this.props.handleClosePopup();
        focusOnPopupAndTabNavigationObject.focusOnCloseHandler('.logoWrap a');
    }

    mountKeyHandlerEvent = () => {
        const isModalDialog = document.querySelector('.modal-dialog');
        if (isModalDialog) {
            isModalDialog.addEventListener('keydown', (e) => focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.modal-dialog', 'button'));
        }
    }

    unmountKeyHandlerEvent = () => {
        const isModalDialog = document.querySelector('.modal-dialog');
        if (isModalDialog) {
            isModalDialog.removeEventListener('keydown', (e) => focusOnPopupAndTabNavigationObject.focusOnKeyPressHandler(e, '.modal-dialog', 'button'));
        }
    }

    componentDidMount() {
        focusOnPopupAndTabNavigationObject.focusOnOpenHandler('.modal-dialog,button,.close');
        this.mountKeyHandlerEvent();
    }
    componentWillUnmount() {
        this.unmountKeyHandlerEvent();
    }

    render() {
        const { confirmationMessage } = this.props;

        return (
            <Modal data-testid="confirmationPopupCmp" show={true} ref={this.popupModal}>
                <Modal.Header>
                    <h5 className="modal-title" id="">Confirm</h5>
                    <button
                        data-testid="handleClosePopupBtn"
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleClosePopup}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>

                </Modal.Header>

                <Modal.Body>
                    <p className="astm-type-body">{confirmationMessage}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button data-testid="handleClosePopupBtn" aria-label="No" variant="secondary" onClick={this.handleClosePopup} className="btn astm-btn btn-light ">No</Button>
                    <Button data-testid="handleConfirmBtn" aria-label="Yes" variant="secondary" onClick={this.handleConfirm} className="btn astm-btn btn-secondary ml10">Yes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default ConfirmationPopUp;