import React from "react";

const SummaryAvailableVotes = props => {
    const { produceravailablevote, iscommitteeclassified, showavailablevote } = props;
    let voteClass = "available-votes";
    if (!produceravailablevote) {
        voteClass = "zero-votes";
    } else if (produceravailablevote < 0) {
        voteClass = "over-votes";
    }

    if (!iscommitteeclassified) {
        return (
            <div className="summary-status">Unclassified Committee</div>
        );
    } 
        if (showavailablevote) {
            return (
                <>
                    <div data-testid="SummaryAvailableVotesCmp" className={"summary-status " + voteClass}>Producer Votes Available: {produceravailablevote}</div>
                    {voteClass === "over-votes" ? (
                        <div className="alert astm-alert bg-danger--light" role="alert">
                            <span className="astm-alert-text">Committee is out of balance!</span>
                        </div>

                    ) : null}
                </>
            );
        }
    

    return null;
}
export default SummaryAvailableVotes;
