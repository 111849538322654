import types from './types';
  
export const setRosterSortOrder = (rosterOrderObj) => ({
    type: types.SET_ROSTER_SORT_ORDER,
    payload: rosterOrderObj
});
export const clearRosterSortOrder = () => ({
    type: types.CLEAR_ROSTER_SORT_ORDER,
});

export const setPendingSortOrder = (pendingOrderObj) => ({
    type: types.SET_PENDING_SORT_ORDER,
    payload: pendingOrderObj
});
export const clearPendingSortOrder = () => ({
    type: types.CLEAR_PENDING_SORT_ORDER,
});

export const setWaitingSortOrder = (waitingOrderObj) => ({
    type: types.SET_WAITING_SORT_ORDER,
    payload: waitingOrderObj
});
export const clearWaitingSortOrder = () => ({
    type: types.CLEAR_WAITING_SORT_ORDER,
});

export const setClassFilter = (classFilter) => ({
    type: types.SET_CLASS_FILTER,
    payload: classFilter
});
export const setVoteFilter = (voteFilter) => ({
    type: types.SET_VOTE_FILTER,
    payload: voteFilter
});
export const setBasicSearch = (basicSearchText) => ({
    type: types.SET_BASIC_SEARCH,
    payload: basicSearchText
});
export const clearAllFilter = () => ({
    type: types.CLEAR_ALL_FILTER,
});

export const searchByFirstName = (firstName, results, isLoading) => ({
    type: types.SEARCH_BY_FIRST_NAME,
    payload: {
        search: firstName,
        results: results,
        isLoading: isLoading
    }
});

export const searchByLastName = (lastName, results, isLoading) => ({
    type: types.SEARCH_BY_LAST_NAME,
    payload: {
        search: lastName,
        results: results,
        isLoading: isLoading
    }
});

export const searchByOrganization = (organizationName, results, isLoading) => ({
    type: types.SEARCH_BY_ORGANIZATION,
    payload: {
        search: organizationName,
        results: results,
        isLoading: isLoading
    }
});


export const setAdvanceSearch = (advanceSearchObj) => ({
    type: types.SET_ADVANCE_SEARCH,
    payload: advanceSearchObj
});


export const resetAdvanceSearch = () => ({
    type: types.RESET_ADVANCE_SEARCH
});

export default {
    setRosterSortOrder,
    clearRosterSortOrder,

    setPendingSortOrder,
    clearPendingSortOrder,

    setWaitingSortOrder,
    clearWaitingSortOrder,

    setClassFilter,
    setVoteFilter,
    setBasicSearch,
    clearAllFilter
}