import React, { Component } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash'
import { Multiselect as MultiselectComponent } from 'react-widgets' // Docs https://jquense.github.io/react-widgets/docs/Multiselect
import { searchByOrganization } from '../../redux/filterValues/actions';
import { defaultLengthOfVisibleOptions } from '../../helpers/constant';

class OrganisationSearchComponent extends Component {

    enterKeyPressCount = 0;
    modifiedValuesData = {
        currentKey: '',
        equivalentValues: []
    };
    constructor(props) {
        super(props);
        this.state = {
            hideDropdown: false
        };
    }

    componentDidMount() {
        this.props.resetSearchQuery();
    }

    handleOnChange = (data, actionEvent) => {
        if (actionEvent.originalEvent ? actionEvent.originalEvent.keyCode !== 13 : actionEvent.keyCode !== 13) {
            this.enterKeyPressCount = 1;
        }
        this.orgToggleHandler(true);

        const originalValues = this.getOriginalValues(data);
        this.props.changeHandler(originalValues);
        this.props.resetSearchQuery();
    }

    getOriginalValues(values) {
        const modifiedValues = JSON.parse(JSON.stringify(values));
        if (modifiedValues.indexOf(this.modifiedValuesData.currentKey) !== -1) {
            const toModifyIndex = modifiedValues.indexOf(this.modifiedValuesData.currentKey);
            values.splice(toModifyIndex, 1, ...this.modifiedValuesData.equivalentValues);
        }
        return values;
    }

    handleSearchChange = (data) => {
        const { membersList } = this.props;
        this.orgToggleHandler(true);
        if (membersList && membersList.committeemembers.length > 0) {
            this.props.fetchMemberListByOrganizationNameLocal(data, membersList.committeemembers)
        }
    }

    handleKeyUp = (event) => {
        const { organizationName, values } = this.props;
        const { search } = organizationName;

        this.orgToggleHandlerKeyUp(event.target);
        if (event.keyCode !== 38 && event.keyCode !== 40) {
            this.modifyOptions([], []);
            this.orgToggleHandler(true);
        } else if (event.keyCode === 38 || event.keyCode === 40) {
            this.orgToggleHandler(false);
        }

        if (event.keyCode === 13) {
            if (search !== '') {
                const modifiedValues = this.modifyValues();

                const data = [...modifiedValues, search];
                this.modifyOptions([], []);
                this.orgToggleHandler(true);
                this.handleOnChange(data, event);
                this.addFocusToDropdown();
                return true;
            }
            this.enterKeyPressCount++;
        } else {
            this.enterKeyPressCount = 0;
        }
        if (this.enterKeyPressCount === 2 && search === '' && values.length > 0) {
            this.enterKeyPressCount = 0;
            this.props.clickSearchHandler(values);
        }
    }

    addFocusToDropdown() {
        let isAutoFocusDOM = document.querySelector('.organisation-search input');
        this.setState({
            hideDropdown: true
        }, () => {
            if (this.state.hideDropdown) {
                this.setState({
                    hideDropdown: false
                }, () => {
                    if (isAutoFocusDOM) isAutoFocusDOM.focus()
                });
            }
        });
    }

    handleOnBlur = (event) => {
        const { organizationName } = this.props;
        const { search } = organizationName;

        this.orgToggleHandler(true);

        if (search !== '') {
            const modifiedValues = this.modifyValues();
            const data = [...modifiedValues, search];
            this.handleOnChange(data, event);
            this.addFocusToDropdown();
            return true;
        }
    }

    modifyOptions(data, selectedOptions) {
        let options = [];
        let newResults = [];
        let { maxLength } = this.props;
        maxLength = maxLength ? maxLength : 10;
        const resultsCount = data.length > maxLength ? maxLength : data.length;
        for (let i = 0; i < resultsCount; i++) {
            if (this.modifiedValuesData.equivalentValues.indexOf(data[i].organization) === -1) {
                options.push({
                    key: data[i].organization + "_search_" + i,
                    text: data[i].organization,
                    value: data[i].organization
                });
                newResults.push(data[i].organization);
            }
        }
        for (let i = 0; i < selectedOptions.length; i++) {
            if (newResults.indexOf(selectedOptions[i]) === -1) {
                options.push({
                    key: selectedOptions[i] + "_selected_" + i,
                    text: selectedOptions[i],
                    value: selectedOptions[i]
                });
            }
        }
        let optionsList = options.map(item => item['text']);
        this.checkOptionsCount(optionsList);
        return optionsList;
    }

    modifyValues() {
        const { values } = this.props;
        const currentValues = JSON.parse(JSON.stringify(values));
        if (currentValues.length > defaultLengthOfVisibleOptions) {
            const extraValuesCount = currentValues.length - defaultLengthOfVisibleOptions;
            const extraValues = currentValues.splice(defaultLengthOfVisibleOptions, extraValuesCount);
            this.modifiedValuesData = {
                currentKey: '+' + extraValuesCount,
                equivalentValues: extraValues
            };
            currentValues.push(this.modifiedValuesData.currentKey);
        } else {
            this.modifiedValuesData = {
                currentKey: '',
                equivalentValues: []
            };
        }
        return currentValues;
    }

    // TODO : Get Selector.
    getDropDownSelector = (element) => {
        return document.querySelector('.organisation-search ' + element);
    }

    // TODO : Check options list count
    checkOptionsCount = (optionsList) => {
        let isExpanded = this.getDropDownSelector('.rw-popup-transition-exited');
        if (optionsList.length > 0 && isExpanded) {
            this.orgToggleHandler(false)
        } else {
            this.orgToggleHandler(true);
        }

    }

    // TODO : Toggle handler for accessibility issue.
    orgToggleHandlerKeyUp = (target) => {
        let multiSelectRef = this.getDropDownSelector('.rw-popup ul');
        if (multiSelectRef && target) {
            if (target.value && target.value.length === 0) {
                multiSelectRef.setAttribute('aria-hidden', true);
            }
        }
    }

    // TODO : Toggle handler for accessibility issue.
    orgToggleHandler = (isOpen) => {
        let multiSelectRef = this.getDropDownSelector('.rw-popup ul');
        if (multiSelectRef) {
            multiSelectRef.setAttribute('aria-hidden', isOpen);
        }
    }

    render() {
        const { organizationName, label, placeholder } = this.props;
        const { hideDropdown } = this.state;
        const { search, results, isLoading } = organizationName;
        const modifiedValues = this.modifyValues();
        const options = this.modifyOptions(results, modifiedValues);

        const customInputs = {
            className: this.props.className ? this.props.className + ' organisation-search' : 'organisation-search'
        };
        if (hideDropdown) {
            return null;
        }
        return (
            <div data-testid="OrgSearchCmp" >
                {label && <label>{label}</label>}
                <MultiselectComponent data-testid="multiSelect"
                    filter={'contains'}
                    open={!isLoading && search !== '' && options.length > 0}
                    busy={isLoading}
                    busySpinner={<i className="fas fa-sync fa-spin" />}
                    messages={{ emptyList: 'No results found', emptyFilter: 'No results found' }}
                    value={modifiedValues}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    onKeyUp={this.handleKeyUp}
                    onFocus={() => { this.orgToggleHandler(true); }}
                    onSearch={debounce(this.handleSearchChange, 500)}
                    data={options}
                    placeholder={placeholder}
                    name={label}
                    {...customInputs}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { roster, filters } = state;
    return {
        organizationName: filters.organizationName,
        committeeid: roster.selectedCommittee,
        membersList: roster.memberList
    }
};

const mapDispatchToProps = dispatch => ({
    resetSearchQuery: () => dispatch(searchByOrganization('', [], false)),
    fetchMemberListByOrganizationNameLocal: (organization, memberList) => {
        dispatch(searchByOrganization(organization, [], true));
        const newMemberList = memberList.filter(member => {
            const re = new RegExp(organization, 'i');
            const isMatch = re.test(member.organization);
            if (isMatch) {
                return member;
            }
            return false;
        })
        dispatch(searchByOrganization(organization, newMemberList, false));
    }
})

const OrganisationSearch = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrganisationSearchComponent);

export default OrganisationSearch;