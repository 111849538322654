/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { blankValue, activitywiseFieldName } from '../../helpers/constant';

class MemberClassificationInformationComponent extends Component {

    renderManufacturerInformation(data) {
        let {
            organizationname,
            divisionname,
            parentorganization,
            organizationwebsite,
            description
        } = !isEmpty(data) ? data : {};
        return (
            <React.Fragment>
                <Row>
                    {this.renderPrimaryActivityColumn()}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Organization Name</label>
                        <p className="astm-type-body--lead">{organizationname ? organizationname : blankValue}</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <label>Division Name</label>
                        <p className="astm-type-body--lead">{divisionname ? divisionname : blankValue}</p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <label>Parent Organization</label>
                        <p className="astm-type-body--lead">{parentorganization ? parentorganization : blankValue}</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <label>Organization Website</label>
                        <p className="astm-type-body--lead"><a href={organizationwebsite ? "http://" + organizationwebsite : null} target="_blank" rel="noreferrer">{organizationwebsite ? organizationwebsite : blankValue}</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <label>Describe your products or services</label>
                        <p className="astm-type-body--lead">{description ? description : blankValue}</p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderGovernmentAgencyInformation(data) {
        let {
            governmentagencyname,
            branch,
            websiteaddress,
            governmentinterest
        } = !isEmpty(data) ? data : {};
        return (
            <React.Fragment>
                <Row>
                    {this.renderPrimaryActivityColumn()}
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <label>Government Agency Name</label>
                        <p className="astm-type-body--lead">{governmentagencyname ? governmentagencyname : blankValue}</p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <label>Branch/Office/Division</label>
                        <p className="astm-type-body--lead">{branch ? branch : blankValue}</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <label>Website address of agency</label>
                        <p className="astm-type-body--lead"><a href={websiteaddress ? "http://" + websiteaddress : null} target="_blank" rel="noreferrer">{websiteaddress ? websiteaddress : blankValue}</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Government interest(s)</label>
                        <p className="astm-type-body--lead">{governmentinterest ? governmentinterest : blankValue}</p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderAcademiaInformation(data) {
        let {
            universityname,
            department,
            websiteaddress,
            academiainterest
        } = !isEmpty(data) ? data : {};
        return (
            <React.Fragment>
                <Row>
                    {this.renderPrimaryActivityColumn()}
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <label>University/College/Institution Name</label>
                        <p className="astm-type-body--lead">{universityname ? universityname : blankValue}</p>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <label>Department</label>
                        <p className="astm-type-body--lead">{department ? department : blankValue}</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <label>Website address</label>
                        <p className="astm-type-body--lead"><a href={websiteaddress ? "http://" + websiteaddress : null} target="_blank" rel="noreferrer">{websiteaddress ? websiteaddress : blankValue}</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <label>Academia interest(s)</label>
                        <p className="astm-type-body--lead">{academiainterest ? academiainterest : blankValue}</p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderConsultantInformation(data) {
        let {
            nameofyourconsultingfirm,
            ismultipleorganizations,
            nameoforganizationrepresenting,
            organizationwebsite,
            isproducerorganization,
            producerorganizationvalue
        } = !isEmpty(data) ? data : {};
        return (
            <React.Fragment>
                <Row>
                    {this.renderPrimaryActivityColumn()}
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <label>Name of Consulting firm</label>
                        <p className="astm-type-body--lead">{nameofyourconsultingfirm ? nameofyourconsultingfirm : blankValue}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Retained by one/multiple organization(s)</label>
                        <p className="astm-type-body--lead">{ismultipleorganizations ? 'Multiple organizations' : 'One organization'}</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <label>Name of Organization Representing</label>
                        <p className="astm-type-body--lead">{nameoforganizationrepresenting ? nameoforganizationrepresenting : blankValue}</p>
                    </Col>
                </Row>
                {
                    ismultipleorganizations ?
                        <Row>
                            <Col xs={12} md={12}>
                                <label>Producer Organization</label>
                                <p className="astm-type-body--lead">{isproducerorganization ? 'Yes' : 'No'}</p>
                            </Col>
                            <Col xs={12} md={6}>
                                <label>{isproducerorganization ? 'Products Sold' : 'Business Activities'}</label>
                                <p className="astm-type-body--lead">{producerorganizationvalue ? producerorganizationvalue : blankValue}</p>
                            </Col>
                        </Row> :
                        <>
                            <Row>
                                <Col xs={12} md={6}>
                                    <label>Organization's Website</label>
                                    <p className="astm-type-body--lead"><a href={organizationwebsite ? "http://" + organizationwebsite : null} target="_blank" rel="noreferrer">{organizationwebsite ? organizationwebsite : blankValue}</a></p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <label>Producer Organization</label>
                                    <p className="astm-type-body--lead">{isproducerorganization ? 'Yes' : 'No'}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <label>{isproducerorganization ? 'Products Sold' : 'Business Activities'}</label>
                                    <p className="astm-type-body--lead">{producerorganizationvalue ? producerorganizationvalue : blankValue}</p>
                                </Col>
                            </Row>
                        </>
                }
            </React.Fragment>
        );
    }

    renderConsumerInformation(data) {
        let {
            isadvocacygroup,
            nameofconsumeradvocacy,
            contactemailofconsumeradvocacy,
            consumerinterest
        } = !isEmpty(data) ? data : {};
        return (
            <React.Fragment>
                <Row>
                    {this.renderPrimaryActivityColumn()}
                </Row>
                { isadvocacygroup ? (
                    <>
                        <Row>
                            <Col xs={12} md={6}>
                                <label>Name of Consumer Advocacy Group</label>
                                <p className="astm-type-body--lead">{nameofconsumeradvocacy ? nameofconsumeradvocacy : blankValue}</p>
                            </Col>
                            <Col xs={12} md={6}>
                                <label>Advocacy Group Contact Email</label>
                                <p className="astm-type-body--lead">{contactemailofconsumeradvocacy ? contactemailofconsumeradvocacy : blankValue}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <label>Consumer Interest(s)</label>
                                <p className="astm-type-body--lead">{consumerinterest ? consumerinterest : blankValue}</p>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Col xs={12} md={12}>
                            <label>Consumer Interest(s)</label>
                            <p className="astm-type-body--lead">{consumerinterest ? consumerinterest : blankValue}</p>
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        );
    }

    renderConsumerAdvocacyInformation(data) {
        let {
            nameofconsumeradvocacy,
            consumerinterest,
        } = !isEmpty(data) ? data : {};
        return (
            <React.Fragment>
                <Row>
                    {this.renderPrimaryActivityColumn()}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Name of Consumer Advocacy Group</label>
                        <p className="astm-type-body--lead">{nameofconsumeradvocacy ? nameofconsumeradvocacy : blankValue}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Consumer Interest(s)</label>
                        <p className="astm-type-body--lead">{consumerinterest ? consumerinterest : blankValue}</p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderOtherInformation(data) {
        let {
            memberaffiliation,
            otherinterest
        } = !isEmpty(data) ? data : {};
        return (
            <React.Fragment>
                <Row>
                    {this.renderPrimaryActivityColumn()}
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Member's Affiliation</label>
                        <p className="astm-type-body--lead">{memberaffiliation ? memberaffiliation : blankValue}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Other interest(s)</label>
                        <p className="astm-type-body--lead">{otherinterest ? otherinterest : blankValue}</p>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }

    renderPrimaryActivityColumn() {
        let { primaryactivityname } = this.props.memberClassfication;
        return (
            <Col xs={12} md={12}>
                <label>Primary Activity</label>
                <p className="astm-type-body--lead">{primaryactivityname ? primaryactivityname : blankValue}</p>
            </Col>
        );
    }

    renderClassificationData(memberClassfication) {
        let { primaryactivityid } = memberClassfication;
        let fieldName = false;
        let data = {};
        for (let i = 0; i < activitywiseFieldName.length; i++) {
            if (activitywiseFieldName[i].primaryActivityIds.indexOf(primaryactivityid) > -1) {
                fieldName = activitywiseFieldName[i].fieldName;
                break;
            }
        }
        if (fieldName && memberClassfication[fieldName] != null) {
            data = memberClassfication[fieldName];
        }
        switch (fieldName) {
            case 'manufacturer':
                return (
                    <React.Fragment>
                        {this.renderManufacturerInformation(data)}
                    </React.Fragment>
                );
            case 'governmentagency':
                return (
                    <React.Fragment>
                        {this.renderGovernmentAgencyInformation(data)}
                    </React.Fragment>
                );
            case 'academia':
                return (
                    <React.Fragment>
                        {this.renderAcademiaInformation(data)}
                    </React.Fragment>
                );
            case 'consultant':
                return (
                    <React.Fragment>
                        {this.renderConsultantInformation(data)}
                    </React.Fragment>
                );
            case 'consumer':
                return (
                    <React.Fragment>
                        {this.renderConsumerInformation(data)}
                    </React.Fragment>
                );
            case 'consumeradvocacy':
                return (
                    <React.Fragment>
                        {this.renderConsumerAdvocacyInformation(data)}
                    </React.Fragment>
                );
            case 'other':
                return (
                    <React.Fragment>
                        {this.renderOtherInformation(data)}
                    </React.Fragment>
                );
            default:
                break;
        }
    }

    render() {
        let { memberClassfication } = this.props;
        let { primaryactivityname } = this.props.memberClassfication;
        return (
            <div data-testid="ClassificationDataCmp" className="popup-subhead-content">
                {primaryactivityname ? <div>{this.renderClassificationData(memberClassfication)}</div> : <div className="no-notes mb20">No records to display.</div>}
            </div>
        );
    }
}

export default MemberClassificationInformationComponent;