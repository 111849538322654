import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { blankValue } from '../../helpers/constant';
import { Row, Col } from 'react-bootstrap'

class MemberContactInfoComponent extends Component {

    render() {
        let {
            organizationname,
            address,
            city,
            state,
            postalcode,
            country,
            phone,
            fax,
            email
        } = this.props.memberContactDetail;

        return (
            <div data-testid="MemberContactInfoCmp" className="popup-subhead-content">

                <Row>
                    <Col xs={12} md={6}>
                        <label>Organization</label>
                        <div className="label-content">{isEmpty(organizationname) ? blankValue : organizationname}</div>

                    </Col>
                    <Col xs={12} md={6}>
                        <label>Address</label>
                        <div className="label-content">{isEmpty(address) ? blankValue : address}</div>

                    </Col>

                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>City</label>
                        <div className="label-content">{isEmpty(city) ? blankValue : city}</div>

                    </Col>
                    <Col xs={12} md={6}>
                        <label>State/Province</label>
                        <div className="label-content">{isEmpty(state) ? blankValue : state}</div>

                    </Col>

                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Postal Code</label>
                        <div className="label-content">{isEmpty(postalcode) ? blankValue : postalcode}</div>


                    </Col>
                    <Col xs={12} md={6}>
                        <label>Country</label>
                        <div className="label-content">{isEmpty(country) ? blankValue : country}</div>

                    </Col>

                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Telephone</label>
                        <div className="label-content">{isEmpty(phone) ? blankValue : phone}</div>


                    </Col>
                    <Col xs={12} md={6}>
                        <label>Fax</label>
                        <div className="label-content">{isEmpty(fax) ? blankValue : fax}</div>

                    </Col>

                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <label>Email Id</label>
                        <div className="label-content">{isEmpty(email) ? blankValue : email}</div>

                    </Col>
                    <Col xs={12} md={6}></Col>
                </Row>
            </div>
        );
    }
}

export default MemberContactInfoComponent;