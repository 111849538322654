import types from './types';
  
export const updateClassAndVoteLoader = (memberId, isShow, value, inputType) => ({
    type: types.UPDATE_CLASS_AND_VOTE_LOADER,
    memberId,
    isShow,
    value,
    inputType
});

export default {
    updateClassAndVoteLoader
}