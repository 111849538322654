const SET_ROSTER_SORT_ORDER = 'SET_ROSTER_SORT_ORDER';
const CLEAR_ROSTER_SORT_ORDER = 'CLEAR_ROSTER_SORT_ORDER';

const SET_PENDING_SORT_ORDER = 'SET_PENDING_SORT_ORDER';
const CLEAR_PENDING_SORT_ORDER = 'CLEAR_PENDING_SORT_ORDER';

const SET_WAITING_SORT_ORDER = 'SET_WAITING_SORT_ORDER';
const CLEAR_WAITING_SORT_ORDER = 'CLEAR_WAITING_SORT_ORDER';

const SET_CLASS_FILTER = 'SET_CLASS_FILTER';
const SET_VOTE_FILTER = 'SET_VOTE_FILTER';
const SET_BASIC_SEARCH = 'SET_BASIC_SEARCH';
const CLEAR_ALL_FILTER = 'CLEAR_ALL_FILTER';

const SEARCH_BY_FIRST_NAME = 'SEARCH_BY_FIRST_NAME';
const SEARCH_BY_LAST_NAME = 'SEARCH_BY_LAST_NAME';
const SEARCH_BY_ORGANIZATION = 'SEARCH_BY_ORGANIZATION';


const SET_ADVANCE_SEARCH = 'SET_ADVANCE_SEARCH';
const RESET_ADVANCE_SEARCH = 'RESET_ADVANCE_SEARCH';

export default {
    SET_ROSTER_SORT_ORDER,
    CLEAR_ROSTER_SORT_ORDER,

    SET_PENDING_SORT_ORDER,
    CLEAR_PENDING_SORT_ORDER,

    SET_WAITING_SORT_ORDER,
    CLEAR_WAITING_SORT_ORDER,

    SET_CLASS_FILTER,
    SET_VOTE_FILTER,
    SET_BASIC_SEARCH,
    CLEAR_ALL_FILTER,

    SEARCH_BY_FIRST_NAME,
    SEARCH_BY_LAST_NAME,
    SEARCH_BY_ORGANIZATION,

    SET_ADVANCE_SEARCH,
    RESET_ADVANCE_SEARCH
}