import React from 'react';
import { Spinner } from 'react-bootstrap';

const CustomLoader = ({ testId, size, isVisible, ...restProps }) => {
    return (
        isVisible && <div data-testid={testId ? testId : 'customLoaderCmp'} active='true'>
            <div className="commonLoader">
                <div className="loaderWrap">
                    <Spinner {...restProps} animation="border" size={size ? size : 'lg'} />
                </div>
            </div>
        </div>)
}
export default CustomLoader;
