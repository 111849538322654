import types from './types';
import loginTypes from '../login/types';

const INITIAL_STATE = {
  isCommitteeListLoading: true,
  committeeList: null,
  selectedCommittee: null,
  isSummaryLoading: true,
  committeeSummary: null,
  isMembersLoading: true,
  isClassListLoading: true,
  isVoteListLoading: true,
  memberList: null,
  committeeClassificationList: null,
  voteList: null,
  rosterReport: null,
  fieldUpdateObj: [],
  currentMemberId: null,
  currentPopupOrigin: null,
  rosterReportsExcelData: {},
  downloadExcelFlag: false,
  pdfLoaderFlag: false,
  updatedPendingApplication: 0,
}

const rosterReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.UPDATE_PENDING_APPLICATION: {
      const { payload } = action;
      return {
        ...state,
        updatedPendingApplication: payload
      }
    }

    case types.SET_ALL_COMMITTEE_LIST: {
      return {
        ...state,
        committeeList: action.payload,
        isCommitteeListLoading: false
      }
    }

    case types.SET_SELECTED_COMMITTEE: {
      return {
        ...state,
        selectedCommittee: action.payload
      }
    }

    case types.SHOW_SUMMARY_LOADER: {
      return {
        ...state,
        isSummaryLoading: true
      }
    }
    case types.HIDE_SUMMARY_LOADER: {
      return {
        ...state,
        isSummaryLoading: false
      }
    }
    case types.SET_COMMITTEE_SUMMARY: {
      return {
        ...state,
        isSummaryLoading: false,
        committeeSummary: action.payload
      }
    }

    case types.SHOW_MEMBER_LOADER: {
      return {
        ...state,
        isMembersLoading: true
      }
    }
    case types.HIDE_MEMBER_LOADER: {
      return {
        ...state,
        isMembersLoading: false
      }
    }
    case types.SET_MEMBER_LIST: {
      return {
        ...state,
        isMembersLoading: false,
        memberList: action.payload
      }
    }
    case types.SET_MEMBER_ID: {
      return {
        ...state,
        currentMemberId: action.payload
      }
    }
    case types.SET_CURRENT_POPUP_ORIGIN: {
      return {
        ...state,
        currentPopupOrigin: action.payload
      }
    }
    case types.SET_CLASS_LIST_LOADER: {
      return {
        ...state,
        isClassListLoading: action.status
      }
    }
    case types.SET_VOTE_LIST_LOADER: {
      return {
        ...state,
        isVoteListLoading: action.status
      }
    }
    case types.SET_COMMITTEE_CLASSIFICATION_LIST: {
      return {
        ...state,
        committeeClassificationList: action.payload
      }
    }
    case types.SET_VOTE_LIST: {
      return {
        ...state,
        voteList: action.payload
      }
    }
    case types.SET_ROSTER_REPORT: {
      return {
        ...state,
        rosterReport: action.payload
      }
    }
    case loginTypes.SIGN_OUT: {
      return {
        isCommitteeListLoading: true,
        committeeList: null,
        selectedCommittee: null,
        isSummaryLoading: true,
        committeeSummary: null,
        isMembersLoading: true,
        memberList: null,
        committeeClassificationList: null,
        voteList: null,
        fieldUpdateObj: []
      };
    }

    case types.SET_ROSTER_EXCEL_DATA: {
      return {
        ...state,
        rosterReportsExcelData: action.rosterReportsExcelData,
        downloadExcelFlag: action.downloadExcelFlag
      };
    }

    case types.SET_PDF_LOADER: {
      return {
        ...state,
        pdfLoaderFlag: action.pdfLoaderFlag
      };
    }
    
    default:
      return state;
  }
}

export default rosterReducer;