import React, { useEffect, useState } from 'react';
import { assign } from 'lodash';
import { getUserInfo } from '../../helpers/utilCommon';
import RenderSelect from '../atoms/Select';


export const IdentityDelegationDropdown = ({ ...props }) => {
    const [userInfo, setUserInfo] = useState(null);
    useEffect(() => {
        setUserInfo(getUserInfo());
    }, []);

    const customProps = assign(props, {
        id: props.id ? props.id : props.name ? props.name : "identity-delegation-button",
        disabled: userInfo?.IdentityDelegation?.permission === 'view',
        className:props.className,
        style:userInfo?.IdentityDelegation?.permission === 'view' ? { cursor: 'not-allowed' } : {}
    });

    return(
        <>
            <RenderSelect {...props} {...customProps} />
        </>
    )
}