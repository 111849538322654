/* eslint-disable no-empty-function */
import React, { useEffect, useContext} from 'react';
import { Redirect, Switch, withRouter, Route } from 'react-router-dom';
import { ProtectedRosterRoute } from '../../../hoc/RouteHOC';
import CustomLoader from '../../atoms/CustomLoader';
import ToastMessage from '../../atoms/ToastMessage';
import FooterComp from '../../organisms/Footer';
import HeaderComp from '../../organisms/Header';
import Roster from '../roster';
import { withOktaAuth, SecureRoute } from '@okta/okta-react';
import { cacheManager, getUserInfo } from '../../../helpers/utilCommon';
import { LOGIN_USER_MODEL, USER_INFO } from '../../../models/common.models';
import { get } from 'lodash';
import { datadogLogs } from "@datadog/browser-logs";
import { logoutHandler, oktaExceptionHandler } from '../../../helpers/OktaUtility';

const App = (props) => {
  const { checkUser, verifyMECallForSession, isLoadOKTAConfigurationTest, OIDC, oktaLogin, showToastMessage, isMiniAppLoading, content, uuidNumber, userInfo, userPermission, isAppLoading, authState, isReactTesting, getAuthHeaderToken } = props;
  const featureFlag = useContext(props.featureFlag)?.flagFeatures;
  const verifyUserInfoHandler = () => {
    // TODO : Verify user information has been update while refresh page.
    const userInfoDetail = getUserInfo();
    if (userInfoDetail && getAuthHeaderToken()) {
      let data = {};
      data['request_mode'] = LOGIN_USER_MODEL.VERIFY_INFO;
      data.userInfo = userInfoDetail;
      data.isExcludeStudent = featureFlag?.studentcommitteemanagement;
      oktaLogin(data, () => { });
    }
  }

  const loadOKTAConfiguration = () => {
    OIDC.tokenManager.get('idToken').then(tokenInfo => {
      const useInfo = getUserInfo();
      if (tokenInfo && !useInfo) {
        const tokenClaims = get(tokenInfo, 'claims');
        let data = {};
        data.userInfo = tokenClaims;
        data['request_mode'] = LOGIN_USER_MODEL.MEMBER_LOGIN;
        data.isExcludeStudent = featureFlag?.studentcommitteemanagement;
        oktaLogin(data, () => {
          checkUser();
        });
      }
    }).catch(error => {
      oktaExceptionHandler(error)
    });
  }

  //Check if multiple tab has rmapp logged in and will logout at same time
  React.useEffect(() => {
    window.addEventListener("storage", (e) => {
        if (e.key === 'isMemberStillLoginInAnotherTab' && e.newValue === 'false') {
          logoutHandler()
        }
    });
  }, [])

  useEffect(()=>{
    checkUser();
    verifyMECallForSession();
    verifyUserInfoHandler();
    if (isLoadOKTAConfigurationTest) {
      loadOKTAConfiguration();
    }
  },[]);

  React.useEffect(() => {
    if (authState?.isAuthenticated) {
      loadOKTAConfiguration();
    }
    
    const userDetails = JSON.parse(cacheManager.getItem(USER_INFO));
    if (userDetails) {
      datadogLogs.setUser({
        MAEUserID: userDetails.MAEUserID,
        memberAccountNumber: userDetails.memberAccountNumber,
      });
    } else {
      datadogLogs.clearUser();
    }
  }, [authState]);

  const Router = isReactTesting ? Route : SecureRoute;

  return (
    <>
        {isAppLoading || authState?.isPending ? < CustomLoader testId='app-spinner' isVisible={true} /> : null}
        {isMiniAppLoading ? <CustomLoader testId='mini-spinner' isVisible={true} /> : null}
        {showToastMessage && (<div className="topAlert"><ToastMessage content={content} uuidNumber={ uuidNumber} /></div>)}
        <Switch>
          {userInfo ? <Redirect exact from="/" to="/app" /> : null}
          {!userInfo ? <Redirect exact from="/app" to="/" /> : null}
          <ProtectedRosterRoute exact path='/app' userPermission={userPermission} {...props} >
          <HeaderComp {...props} featureFlag={featureFlag}/>
          <Roster {...props} featureFlag={featureFlag}/>
            <FooterComp />
          </ProtectedRosterRoute>
          <Router exact path="/" />
          <Redirect from={'/login'} to="/app" />
          <Route path='*' component={(props) => {
            const { pathname } = props.location;
            if (pathname !== '/auth') {
              window.open(process.env.REACT_APP_PUB_404_URL, "_self");
            }
            return false;
          }} />
        </Switch>
      </>
  )
}

export default withOktaAuth(withRouter(App));