import React from "react";
import RenderSelect from '../atoms/Select';

// reponsive
import MediaQuery from "react-responsive";
import { maxWidthValue } from "../../helpers/constant";

const RosterHeader = props => {
    const {
        selectedCommitteeWithPermission,
        totalpendingapplications,
        isMembersLoading,
        selectedCommittee,
        committeeOptions,
        onChangeCommitteeHandler
    } = props;

    return (
        <div data-testid="RosterHeaderCmp" className="roster-header clearfix">
            <div className="leftPane">
                <div className="roster-heading">Roster Maintenance</div>
            </div>
            <div className="rightPane">
                <div className="appSummary clearfix">
                    <div className="floatLeft">
                        <span className="title mr5">Total Pending Applications For All Committees</span>
                        <span className="astm-badge bg-danger badge">{totalpendingapplications}</span>
                    </div>
                    <div className="floatRight">
                        <label className="title d-inline-block" htmlFor="SelectCommittee">Select Committee</label>
                        <RenderSelect
                            size="sm"
                            name="SelectCommittee"
                            id="SelectCommittee"
                            className="dropdown-loader"
                            options={committeeOptions}
                            onChange={onChangeCommitteeHandler}
                            value={selectedCommittee}
                            loading={isMembersLoading ? true : false}
                            disabled={isMembersLoading ? true : false}
                        />
                    </div>
                </div>
            </div>
            <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
                {isMembersLoading ? null : selectedCommitteeWithPermission.updateClassification || selectedCommitteeWithPermission.updateVoting ? null : (
                    <span className="permission-message">You do not have the permissions to edit Roster.</span>
                )}
            </MediaQuery>
        </div>
    );
}
export default RosterHeader;