import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col, Card } from 'react-bootstrap';
import { map, orderBy, clone, findIndex } from 'lodash';
import moment from 'moment';
import DatePicker from '../atoms/DatePicker';
import RenderRadio from '../atoms/Radiobox';
import RenderInputField from '../atoms/Inputbox';
import Tooltip from '../atoms/Tooltip';
import MultiSelect from '../atoms/MultiSelect';
import OrganisationSearch from '../atoms/OrganisationSearch';
import NameSearch from '../atoms/NameSearch';
import { setAdvanceSearch, resetAdvanceSearch } from '../../redux/filterValues/actions';
import { setMessage } from '../../redux/shared/actions';

let innerWidth = false;

class MemberListAdvanceSearchComponent extends Component {
    constructor(props) {
        super(props);
        const searchProp = props.advanceSearch;
        this.state = {
            advanceSearch: clone(searchProp),
            firstNameSearchValue: searchProp.firstNameSearchValue,
            lastNameSearchValue: searchProp.lastNameSearchValue,
            fromDateSearchValue: searchProp.fromDateSearchValue,
            toDateSearchValue: searchProp.toDateSearchValue,
            classSearchValue: searchProp.classSearchValue,
            voteSearchValue: searchProp.voteSearchValue,
            organisationSearchValue: searchProp.organisationSearchValue,
            emailSearchValue: searchProp.emailSearchValue,
            typeSearchValue: searchProp.typeSearchValue,
            isDateError: null
        };
    }

    componentDidMount() {
        this.updatePopupContentHeight();
    }

    handleOrientation = () => {
        if (innerWidth !== window.innerWidth) this.updatePopupContentHeight();
        innerWidth = window.innerWidth;
    }

    updatePopupContentHeight() {
        if (window.isMobile) {
            let advModalContent = document.getElementsByClassName("advModal-content");
            if (advModalContent.length > 0) {
                advModalContent[0].style.maxHeight = (window.innerHeight - 170) + 'px';
            }
            window.checkWindowResize(this.handleOrientation);
        }
    }

    handleChangeSearch = (value, key) => {
        this.setState({ [key]: value });
    }

    hanldeClassSearchChange = (value) => {
        const positionOfAll = value.indexOf(-1);
        if (positionOfAll !== -1) {
            value = [-1];
        } else {
            const { committeeClassificationList } = this.props;
            if (value.length === committeeClassificationList.length) {
                value = [-1];
            }
        }
        this.setState({ classSearchValue: value });
    }

    hanldeVoteSearchChange = (value) => {
        const positionOfAll = value.indexOf(-1);
        if (positionOfAll !== -1) {
            value = [-1];
        } else {
            const { voteList } = this.props;
            if (value.length === voteList.length) {
                value = [-1];
            }
        }
        this.setState({ voteSearchValue: value });
    }

    handleSearch = (e) => {
        e.preventDefault();
        const {
            firstNameSearchValue,
            lastNameSearchValue,
            fromDateSearchValue,
            toDateSearchValue,
            classSearchValue,
            voteSearchValue,
            organisationSearchValue,
            emailSearchValue,
            typeSearchValue
        } = this.state;

        let rangeFromDate = null;
        let rangeToDate = null;
        if ((fromDateSearchValue !== null && fromDateSearchValue !== '') || (toDateSearchValue !== null && toDateSearchValue !== '')) {
            const currentDate = moment();
            const fromDate = moment(fromDateSearchValue);
            let toDate = moment(toDateSearchValue);
            let hasError = false;
            if (fromDateSearchValue !== null && fromDateSearchValue !== '' && !fromDate.isValid()) {
                this.sendDateError(1001, "from");
                hasError = true;
            }

            if (toDateSearchValue !== null && toDateSearchValue !== '' && !toDate.isValid()) {
                if (hasError) {
                    this.sendDateError(1001, "both");
                } else {
                    this.sendDateError(1001, "to");
                }
                hasError = true;
            }

            if (fromDate.isAfter(toDate)) {
                this.sendDateError(1002, "both");
                hasError = true;
            }

            if (fromDate.isAfter(currentDate)) {
                this.sendDateError(1003, "both");
                hasError = true;
            }
            if (hasError) {
                return false;
            }
        }

        const advanceSearchObj = {
            firstNameSearchValue,
            lastNameSearchValue,
            fromDateSearchValue,
            toDateSearchValue,
            classSearchValue,
            voteSearchValue,
            organisationSearchValue,
            emailSearchValue,
            typeSearchValue,
            rangeFromDate,
            rangeToDate
        }
        advanceSearchObj.fromDateSearchValue = advanceSearchObj.fromDateSearchValue ? moment(advanceSearchObj.fromDateSearchValue).format('MM/DD/YYYY') : '';
        advanceSearchObj.toDateSearchValue = advanceSearchObj.toDateSearchValue ? moment(advanceSearchObj.toDateSearchValue).format('MM/DD/YYYY') : '';

        this.setState({ isDateError: null }, () => {
            const { setAdvanceSearch, onClose } = this.props;
            setAdvanceSearch(advanceSearchObj);
            onClose();
        });
    }

    sendDateError(errorCode, field) {
        this.setState({ isDateError: field }, () => {
            this.props.setMessage(true, errorCode);
        })
    }

    handleReset = (e) => {
        e.preventDefault();
        this.setState({
            firstNameSearchValue: '',
            lastNameSearchValue: '',
            fromDateSearchValue: '',
            toDateSearchValue: '',
            classSearchValue: [],
            voteSearchValue: [],
            organisationSearchValue: [],
            emailSearchValue: '',
            typeSearchValue: 'any',
            isDateError: null
        }, () => {
            const { resetAdvanceSearch, onClose } = this.props;
            resetAdvanceSearch();
            onClose();
        });
    }

    render() {
        const {
            firstNameSearchValue,
            lastNameSearchValue,
            fromDateSearchValue,
            toDateSearchValue,
            classSearchValue,
            voteSearchValue,
            organisationSearchValue,
            emailSearchValue,
            typeSearchValue,
            isDateError
        } = this.state;

        const { committeeClassificationList, voteList } = this.props;

        let classOptions = [{ key: -1, value: -1, text: 'All Classifications' }];
        if (classSearchValue.indexOf(-1) === -1) {
            let sortedClassList = orderBy(committeeClassificationList, ['name'], ['asc']);
            map(sortedClassList, (classObj) => {
                classOptions.push({
                    key: classObj.committeeclassificationid + classObj.name,
                    value: classObj.committeeclassificationid,
                    text: classObj.name
                });
            });
        }
        if (classSearchValue.length > 2) {
            let thirdValue = classSearchValue[2];
            let thirdValueIndex = findIndex(classOptions, { value: thirdValue });
            if (thirdValueIndex >= 0)
                classOptions[thirdValueIndex].text = "+" + (classSearchValue.length - 2);
        }

        let voteOptions = [{ key: -1, value: -1, text: 'All Votes' }];
        if (voteSearchValue.indexOf(-1) === -1) {
            let sortedVoteList = orderBy(voteList, ['description'], ['asc']);
            map(sortedVoteList, (voteObj) => {
                voteOptions.push({
                    key: voteObj.voteid + voteObj.description,
                    value: voteObj.voteid,
                    text: voteObj.description
                });
            });
        }

        if (voteSearchValue.length > 2) {
            let thirdValue = voteSearchValue[2];
            let thirdValueIndex = findIndex(voteOptions, { value: thirdValue });
            if (thirdValueIndex >= 0)
            voteOptions[thirdValueIndex].text = "+" + (voteSearchValue.length - 2);
        }

        return (
            <Card data-testid="memberListAdvsearchCmp" className="advSearchDV">
                <Card.Body>
                    <h6 className="astm-type-heading--h6-bold mb20">Advanced Search <button data-testid="closeAdvancedSearch" title="Close" className="closePopup floatRight" onClick={() => { this.props.onClose(); }}><span className="close" aria-hidden="true">&times;</span></button></h6>

                    <Row className="mb20">
                        <Col xs={12} md={3}>
                            <NameSearch
                                data-testid="NameSearch"
                                label="First name"
                                placeholder="First name"
                                searchField="firstname"
                                value={firstNameSearchValue}
                                changeHandler={(value) => { this.handleChangeSearch(value, 'firstNameSearchValue'); }}
                                clickSearchHandler={this.handleSearch}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <NameSearch
                                label="Last name"
                                placeholder="Last name"
                                searchField="lastname"
                                value={lastNameSearchValue}
                                changeHandler={(value) => { this.handleChangeSearch(value, 'lastNameSearchValue'); }}
                                clickSearchHandler={this.handleSearch}
                            />
                        </Col>
                        <Col xs={12} md={3} className="relative">

                            <div>
                                <DatePicker
                                    name="Join_Date_Range"
                                    id="Join_Date_Range"
                                    className="date-input"
                                    label='Join Date Range'
                                    placeholder='MM/DD/YYYY'
                                    error={(isDateError && (isDateError === 'from' || isDateError === 'both')) ? true : false}
                                    value={fromDateSearchValue}
                                    changeHandler={(value) => { this.handleChangeSearch(value, 'fromDateSearchValue'); }}
                                />
                            </div>
                            <span className="date-range-seprator">to</span>

                        </Col>

                        <Col xs={12} md={3}>
                            <div className="tabletFixPicker">
                                <span className="hideMobile"></span>
                                <DatePicker
                                    name="toDate"
                                    id="toDate"
                                    className="date-input"
                                    placeholder='MM/DD/YYYY'
                                    error={(isDateError && (isDateError === 'to' || isDateError === 'both')) ? true : false}
                                    value={toDateSearchValue}
                                    changeHandler={(value) => { this.handleChangeSearch(value, 'toDateSearchValue'); }}
                                />
                            </div>

                        </Col>
                    </Row>
                    <Row className="mb20">
                        <Col xs={12} md={6}>
                            <MultiSelect
                                name='memberClassification'
                                className="adv-multiselect hideThreePlus"
                                label="Member Classification"
                                placeholder="Select Classification"
                                options={classOptions}
                                changeHandler={(value) => { this.hanldeClassSearchChange(value); }}
                                value={classSearchValue}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <MultiSelect
                                name='officialVote'
                                className="adv-multiselect hideThreePlus"
                                label="Official Vote"
                                placeholder="Select Vote"
                                options={voteOptions}
                                changeHandler={(value) => { this.hanldeVoteSearchChange(value); }}
                                value={voteSearchValue}
                            />
                        </Col>
                    </Row>
                    <Row className="mb20">
                        <Col xs={12} md={6}>
                            <OrganisationSearch
                                name='organization'
                                label="Organization"
                                className="adv-multiselect organisation-search dropdown-loader hideThreePlus type2"
                                placeholder="Search one or more organizations"
                                values={organisationSearchValue}
                                changeHandler={(value) => { this.handleChangeSearch(value, 'organisationSearchValue'); }}
                                clickSearchHandler={this.handleSearch}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <label>Email ID</label>
                            <RenderInputField
                                name='email'
                                id='email'
                                type='email'
                                placeholder='Enter email ID'
                                onChange={(e) => { this.handleChangeSearch(e.target.value, 'emailSearchValue'); }}
                                value={emailSearchValue}
                            />
                        </Col>
                    </Row>
                    <Row className="halfGrid">
                        <Col xs={12} md={9}>
                            <RenderRadio
                                name='matchAnyCriteria'
                                label='Match any of the criteria'
                                value='any'
                                checked={typeSearchValue === 'any'}
                                onChange={(e) => { this.handleChangeSearch(e.target.value, 'typeSearchValue') }}
                            />
                            <RenderRadio
                                name='matchAllCriteria'
                                label='Match all of the criteria'
                                value='all'
                                checked={typeSearchValue === 'all'}
                                onChange={(e) => { this.handleChangeSearch(e.target.value, 'typeSearchValue') }}
                            />
                        </Col>
                        <Col xs={12} md={3} >
                            <div className="txtRight">
                                <Tooltip
                                    content='Reset'
                                    placement='top'
                                    target={
                                        <button data-testid="handleResetBtn" className="btn btn-light iconButton" onClick={this.handleReset} aria-label="reset"><i className="fas fa-sync"></i></button>
                                    }
                                />
                                <Tooltip
                                    content='Search'
                                    placement='top'
                                    target={
                                        <button data-testid="handleSearchBtn" className="btn astm-btn btn-primary ml10 iconButton" onClick={this.handleSearch} aria-label="search"><i className="fas fa-search"></i></button>
                                    }
                                />


                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    const { filters, roster } = state;
    const { committeeClassificationList, voteList } = roster;
    return {
        advanceSearch: filters.advanceSearch,
        classFilter: filters.classFilter,
        voteFilter: filters.voteFilter,
        committeeClassificationList,
        voteList
    }
}

const mapDispatchToProps = dispatch => ({
    setAdvanceSearch: advanceSearchObj => dispatch(setAdvanceSearch(advanceSearchObj)),
    resetAdvanceSearch: () => dispatch(resetAdvanceSearch()),
    setMessage: (flag, code) => dispatch(setMessage(flag, code)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MemberListAdvanceSearchComponent);
