import React from 'react'
import { OverlayTrigger, Tooltip as TooltipBootstrap } from 'react-bootstrap';

const Tooltip = (props) => {
    return (
        <OverlayTrigger key={props.id} placement={props.placement ? props.placement : 'top'} overlay={
            <TooltipBootstrap data-testid="tooltip-component" id={props.id}>{props.content}</TooltipBootstrap>
        }>
            {props.target}
        </OverlayTrigger>
    )
};
export default Tooltip
