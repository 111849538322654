import React from 'react'
import { Form, Button } from 'react-bootstrap'
import Tooltip from '../atoms/Tooltip';

const RenderSearchInputBox = ({ testid, id, label, name, type, required, disabled, placeholder, className, value, onChange, onKeyUp, onClickSearch }) => {

    const handleChange = (event) => {
        onChange && onChange(event);
    }

    const handleKeyUp = (event) => {
        onKeyUp && onKeyUp(event);
    }

    const handleOnClickSearch = (event) => {
        onClickSearch && onClickSearch(event);
    }

    return (
        <Form.Group data-testid="SearchInputCmp">
            {(required && label) && (
                <Form.Label className="astm-type-body--lead" htmlFor={id}>
                    <i className="asterisk  icon"></i>
                    {label}
                </Form.Label>
            )}
            <Form.Control
                data-testid={testid}
                id={id ? id : ''}
                type={type ? type : ''}
                name={name ? name : ''}
                placeholder={placeholder ? placeholder : ''}
                className={className ? className : ''}
                value={value ? value : ''}
                disabled={disabled ? disabled : false}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
            />
            {onClickSearch &&
                <Tooltip
                    id='BasicSearch'
                    content='Search'
                    target={
                        <Button onClick={handleOnClickSearch} data-testid="ClickSearch" disabled={disabled ? disabled : false} variant="primary" aria-label="Search" className="astm-btn btn-primary">
                            <i className="fas fa-search"></i>
                        </Button>
                    }
                />
            }
        </Form.Group>
    )
}
export default RenderSearchInputBox
